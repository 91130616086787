import { Component,   OnInit  } from '@angular/core'; 
@Component({
  selector: 'app-clinic-dashboard',
  templateUrl: './clinic-dashboard.component.html',
  styleUrls: ['./clinic-dashboard.component.scss'],
})
export class ClinicDashboardComponent implements OnInit {
  isAppointment:boolean = true;
  apptSelected: string = "tab-selected";


  usageSelected: string = "report-tab-selected";
  auditSelected: string = "";

  range:any = { start: null, end: null };
  
  cancelBtn:boolean  = false;

  title='Usage Report';
  hasAppointmentPermission: boolean = true;
  hasUsageReportPermission: boolean = true;
  hasAuditReportPermission: boolean = true;

  constructor( ) { }

  ngOnInit() { 
    sessionStorage.removeItem('redirectTo');  
     
  }
   

  preventBack() {
    window.history.forward();
    window.onunload = function () { null };
  }


  activeTab: string = 'appointment';
  isVisibleUsage = true;
  isVisibleAudit = true;
 
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
 
  

  showDateCancel(){
    this.cancelBtn = true;
  }
  clearDate(){ 
    this.range = { start: null, end: null };
  
    this.cancelBtn = false;
  }
  

  isSidebarVisible: boolean = false;

  onSidebarToggle(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  selectedTab: string = 'appointment';

selectTab(tabName: string) {
  this.selectedTab = tabName;
} 
 

}