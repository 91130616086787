<div class="clinic_dashboard_header_main d-flex justify-content-sb px-20 align-items-center borderBottom20">
  <div class="d-flex align-items-center">
    <!-- <div class="menu-icon desktop-menu cursor" (click)="toggleSidebar()"  *ngIf="hasAuditReportPermission && hasUsageReportPermission"> -->
    <div class="menu-icon desktop-menu cursor" (click)="toggleSidebar()" *ngIf="showIcon">
      <ion-icon color="gray80" class="p-5" *ngIf="!isSidebarVisible" src="../../../../../../assets/svg/sidebar-open.svg"></ion-icon>
      <ion-icon color="gray80" class="p-5 side-close" *ngIf="isSidebarVisible" src="../../../../../../assets/svg/sidebar-close.svg"></ion-icon>
    </div>

    <div class="menu-icon tab-menu cursor" id="nav-popover-trigger3"  >
      <ion-icon color="gray80" class="p-5" src="../../../../../../assets/svg/menu.svg"></ion-icon>


      <ion-popover class="menu_popup" trigger="nav-popover-trigger3" arrow="false" side="bottom"
          alignment="start" dismiss-on-select="false" show-backdrop="false" style="--offset-x: 0rem; --offset-y:0.5rem"
          arrow="false"  >
          <ng-template>
            <div class="intake-filter" >
              <div class="p-15"  *ngIf="hasAppointmentPermission">
                <div class="appt cursor d-flex align-items-center w-100 px-10 py-8" (click)="showTab('appointment')"
                  [class]="apptSelected">
                  <ion-icon class="title-32-font-size mr-10" src="../../../../assets/svg/calendar.svg"></ion-icon>
                  <ion-text class="h5-M">Appointments</ion-text>
                </div>
              </div>
      
              <div class="p-15" *ngIf="hasUsageReportPermission || hasAuditReportPermission">
                <ion-text class="bodyS-txt uppercase p-10 pb-0">Reports</ion-text>
                <div  *ngIf="hasUsageReportPermission">
                  <div class="usage-report d-flex align-items-center cursor mt-10"  (click)="showTab('usage-report')"
                    [class]="usageSelected">
                    <div class="d-flex align-items-center w-100 px-10 py-8">
                      <ion-icon class="title-32-font-size mr-10" color="gray80"
                        src="../../../../assets/svg/file-text.svg"></ion-icon>
                      <ion-text class="h5">Usage Report</ion-text>
                    </div>
                    <div class="line-hight-11">
                      <ion-icon class="bodyL-txt p-10" color="gray60" *ngIf="!isVisibleUsage"
                        src="../../../../assets/svg/chevron-down.svg"></ion-icon>
      
                        <ion-icon class="bodyL-txt p-10" color="gray60" *ngIf="isVisibleUsage"
                        src="../../../../assets/svg/chevron-up.svg"></ion-icon>
                    </div>
                  </div>
                  <div class="filter-usage-tab"  *ngIf="activeTab === 'usage-report'">
 
                    
                    <form [formGroup]="filterForm">
                      <!-- Date Range -->
                      <div class="status-form">
                        <div class="wrapper mb-20 mobile-width">
                          <label class="mb-3 bodyS-txt ion-text-uppercase">
                            <ion-text color="gray60">Date Range</ion-text>
                          </label>
                          <br>
                          <kendo-daterange class="d-flex align-items-center ion-justify-content-between">
                            <kendo-dateinput placeholder="From" color="gray100" class="body2-txt" kendoDateRangeStartInput
                              formControlName="startDate"></kendo-dateinput>
                            <ion-text class="mx-15" color="gray100" class="body2-txt">&#8212;</ion-text>
                            <kendo-dateinput placeholder="To" kendoDateRangeEndInput color="gray100" class="body2-txt"
                              formControlName="endDate"></kendo-dateinput>
                          </kendo-daterange>
                        </div>
                      </div>
                    
                      <!-- Status -->
                      <div class="status-form">
                        <div class="wrapper mb-20 mobile-width">
                          <label class="mb-3 bodyS-txt ion-text-uppercase">
                            <ion-text color="gray60">Status</ion-text>
                          </label>
                          <br>
                          <kendo-combobox [data]="Status" valueField="id" textField="name" placeholder="All statuses"
                            formControlName="status"></kendo-combobox>
                        </div>
                      </div>
                    
                      <!-- Appointment Type -->
                      <div class="Appt-field">
                        <div class="wrapper mb-20 mobile-width">
                          <label class="mb-3 bodyS-txt ion-text-uppercase">
                            <ion-text color="gray60">Appointment Type</ion-text>
                          </label>
                          <br>
                          <kendo-combobox [data]="ApptType" valueField="appointmenttypeid" textField="appointmenttypedescription"
                            placeholder="All types" formControlName="appointmentType"></kendo-combobox>
                        </div>
                      </div>
                    
                      <!-- Mode of Completion -->
                      <div class="completion-field">
                        <div class="wrapper mb-20 mobile-width">
                          <label class="mb-3 bodyS-txt ion-text-uppercase">
                            <ion-text color="gray60">Mode of Completion</ion-text>
                          </label>
                          <br>
                          <kendo-combobox [data]="Completion" valueField="id" textField="name" placeholder="All modes"
                            formControlName="completionMode"></kendo-combobox>
                        </div>
                      </div>
                    
                      <!-- Location -->
                      <div class="location-field">
                        <div class="wrapper mb-20 mobile-width">
                          <label class="mb-3 bodyS-txt ion-text-uppercase">
                            <ion-text color="gray60">Location</ion-text>
                          </label>
                          <br>
                          <kendo-multiselect [data]="listItems" formControlName="location" placeholder="All Locations"
                            [autoClose]="false" class="custom-multiselect">
                          </kendo-multiselect>
                        </div>
                      </div>
                    
                      <!-- Buttons -->
                      <div class="d-flex pat_reg">
                        <ion-button type="button" fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn w-100 mr-10"
                          (click)="resetUsageForm()">
                          <ion-text>Reset</ion-text>
                        </ion-button>
                    
                        <ion-button type="submit" fill="solid" expand="block" color="ion-color-white"
                          [disabled]="filterForm.invalid" class="filled-icon-btn capitalize info-btn w-100"
                          (click)="applyFilter()">
                          <ion-text>Apply</ion-text>
                        </ion-button>
                      </div>
                    </form>
                    
      
                  </div>
                </div>
                <div *ngIf="hasAuditReportPermission">
                  <div class="audit-report d-flex align-items-center cursor mt-10" [class]="auditSelected"
                    (click)="showTab('audit-report')">
                    <div class="d-flex align-items-center w-100 px-10 py-8">
                      <ion-icon class="title-32-font-size mr-10" color="gray80"
                        src="../../../../assets/svg/file-text.svg"></ion-icon>
                      <ion-text class="h5">Audit Log</ion-text>
                    </div>
                    <div class="line-hight-11">
                      <ion-icon class="bodyL-txt p-10" color="gray60" *ngIf="!isVisibleAudit"
                        src="../../../../assets/svg/chevron-down.svg"></ion-icon>
                        
                        <ion-icon class="bodyL-txt p-10" color="gray60" *ngIf="isVisibleAudit"
                        src="../../../../assets/svg/chevron-up.svg"></ion-icon>
                    </div>
                  </div>
                  <div class="filter-usage" *ngIf="activeTab === 'audit-report'">
                    <form [formGroup]="auditReportForm">
                      <!-- Status -->
                      <div class="status-form">
                        <div class="wrapper mb-20 mobile-width">
                          <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text color="gray60">Status</ion-text></label>
                          <kendo-combobox [data]="statusAudit" valueField="id" textField="name" placeholder="All statuses"
                            formControlName="status"></kendo-combobox>
                        </div>
                      </div>
                  
                      <!-- Date Range -->
                      <div class="status-form">
                        <div class="wrapper mb-20 mobile-width">
                          <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text color="gray60">Date
                            Range</ion-text></label><br>
                        <kendo-daterange class="d-flex align-items-center ion-justify-content-between" #daterange>
                          <kendo-dateinput placeholder="From" color="gray100" class="body2-txt" kendoDateRangeStartInput
                            [(value)]="range.start" (blur)="showDateCancel()" formControlName="startDate"></kendo-dateinput>
                          <ion-text class="mx-15" color="gray100" class="body2-txt">
                            &#8212;
                          </ion-text>
                          <kendo-dateinput placeholder="To" kendoDateRangeEndInput [(value)]="range.end"
                            (blur)="showDateCancel()" color="gray100" class="body2-txt"  formControlName="endDate"></kendo-dateinput>
                        </kendo-daterange>
                        </div>
                      </div>
                  
                      <!-- Buttons -->
                      <div class="d-flex pat_reg">
                        <ion-button type="button" fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn w-100 mr-10"
                          (click)="resetAuditReportForm()">
                          <ion-text>Reset</ion-text>
                        </ion-button>
                  
                        <ion-button type="submit" fill="solid" expand="block" color="ion-color-white"
                          [disabled]="auditReportForm.invalid" (click)="applyFilterAudit()" class="filled-icon-btn capitalize info-btn w-100">
                          <ion-text>Apply</ion-text>
                        </ion-button>
                      </div>
                    </form>
                  </div>
                 
                </div>
              </div>
            </div>
          </ng-template>
        </ion-popover>
    </div>

    <div class="">
      <div class="text-ellipsis maxWidth500">
        <ion-text class="bodyM-txt-bold " color="gray100">{{tenantName}}</ion-text>
      </div>
      <div class="d-flex align-items-center hideOnPreRegister cursor" id="nav-popover-trigger2" *ngIf="!showHide">
        <!-- Map Icon -->
        <!-- <ion-icon class="bodyS-txt mr-3" src="../../../../../../assets/svg/map-pin 2.svg" color="highlight"></ion-icon> -->
        <ion-text class="bodyS-txt-italic mr-3" color="gray80">{{selectedLocation?.locationdescription}}</ion-text>
        <ion-icon src="../../../../../../assets/svg/chevron-down.svg" color="gray80" class=""></ion-icon>
        <ion-popover class="clinic_list_popup" trigger="nav-popover-trigger2" arrow="false" side="bottom"
          alignment="start" dismiss-on-select="true" show-backdrop="false" style="--offset-x: 0rem; --offset-y:0.5rem"
          arrow="false">
          <ng-template>
            <div class="clinic_location_list_main">
              <div class="clinic_location_list d-flex align-items-center borderBottom-20 px-10 cursor"
                *ngFor="let loc of location; let i=index" (click)="changeLocation($event,loc)">
                <div class="d-flex justify-content-sb w-100">
                  <div class="d-flex w-90">
                    <input type="checkbox" kendoCheckBox value="{{loc}}" [checked]="loc.isChecked" />
                    <kendo-label class="k-checkbox-label cursor"
                      [ngClass]="loc.isChecked == true ? 'bold-font-weight': ''"
                      text="{{loc.locationdescription}}"></kendo-label>
                  </div>
                  <!-- Map Icon -->
                  <ion-icon *ngIf="loc.isdefault" class="bodyM-txt mr-3 w-10 d-inline-block"
                    src="../../../../../../assets/svg/map-pin 2.svg" color="highlight"></ion-icon>
                </div>

              </div>
              <!-- <div class="clinic_location_list d-flex align-items-center borderBottom-20 px-10 cursor">
                <input type="checkbox" kendoCheckBox value="Westside Office" />
                <kendo-label class="k-checkbox-label cursor" text="Westside Office"></kendo-label>
              </div>
              <div class="clinic_location_list d-flex align-items-center px-10 cursor">
                <input type="checkbox" kendoCheckBox value="Market Village Associates" />
                <kendo-label class="k-checkbox-label cursor" text="Market Village Associates"></kendo-label>
              </div> -->
            </div>
          </ng-template>
        </ion-popover>
      </div>

    </div>

  </div>
  <!-- <div class="w-33 dashboard_name" [ngClass]="showHide == true ? 'w-50' : 'w-33'"> -->
  <div class=" dashboard_name">
    <div class="text-center"><ion-text class="bodyL-txt-bold text-blue-color " *ngIf="!showHide && !title  && !usagetitle">Patient
        Self-Registration</ion-text></div>

    <div *ngIf="showHide && !title  && !usagetitle"><ion-text class="bodyL-txt-bold text-blue-color showOnPreRegister">Review Patient
        Information</ion-text></div>
    <!-- <div><ion-text *ngIf="title" class="bodyL-txt-bold text-blue-color showOnPreRegister">Audit Report</ion-text></div> -->
  </div>

  <!-- <div class="d-flex align-items-center justify-content-end widthAuto profile" [ngClass]="showHide == true ? 'w-50' : 'w-33'"> -->
  <div class="d-flex align-items-center justify-content-end widthAuto profile">
    <ion-icon class="title-32-font-size cursor mr-20" src="../../../../../../assets/svg/dashbaord-lock.svg"
      (click)="navigateToLogin()"></ion-icon>
    <ion-avatar class="default-avatar cursor" slot="start" (click)="popOver($event)">
      <img *ngIf="errorLoading" src="{{imgVal}}" />
      <span *ngIf="!errorLoading">{{image?.firstname | initials }}{{image?.lastname | initials}}</span>
    </ion-avatar>
  </div>
  <!-- <ion-popover class="signout_popup_main" arrow="false" trigger="signout-trigger" arrow="false" side="bottom" alignment="end" dismiss-on-select="true" show-backdrop="false" style="--offset-x: -1rem; --offset-y:0.3rem" >
    <ng-template>
     
      <div class="signout_popup ">
          routerLink="/practice-list" ->
         <div class="px-30 py-10 cursor borderBottomGray20" *ngIf="tenantLength.length>1" (click)="removetenant()">
          <ion-text class="bodyM-txt" color="gray80" >Change Pratice</ion-text>
        </div>
        <div (click)="logout()" class="cursor px-30 py-10">
          <ion-text class="bodyM-txt " color="gray80">Logout</ion-text>
        </div>
      </div> 
    </ng-template>
  </ion-popover> -->
</div>