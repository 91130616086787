import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GridDataResult, ModifierKey, MultipleSortSettings, PageChangeEvent, PagerPosition, PagerType } from '@progress/kendo-angular-grid';

import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Workbook } from 'exceljs';
import { messageModel } from 'src/app/core/models/messageModel';
import { CommonService } from 'src/app/core/services/common.service';
import { PatientService } from 'src/app/core/services/patient.service';
import * as fs from "file-saver";
import { AuditService } from '../../pages/AuditService/audit.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Router } from '@angular/router';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss'],
})
export class AuditReportComponent implements OnInit {

  @ViewChild(TooltipDirective)
  public tooltipDir!: TooltipDirective;

  public modifierKey: ModifierKey = "none";
  range: any = { start: null, end: null };
  public sortSettings: MultipleSortSettings = {
    mode: "multiple",
    multiSortKey: this.modifierKey,
  };

  public sort: SortDescriptor[] = [{ field: "Group", dir: "asc" }];


  public gridMessageDetails!: GridDataResult | any;
  messageDetailsData: any = [];
  messageReportFilterForm!: FormGroup;
  messageFilter: messageModel = new messageModel();
  isResetDisable: boolean = false;
  isActionVisible: boolean = false;
  isDateRangeVisible: boolean = false;
  isDate: boolean = false;
  isDateRangeValid: boolean = true;
  isDateRangeError: boolean = false;
  isSkipPage: boolean = false;
  fromDate: any;
  toDate: any;
  tenantId: any;
  isPage: boolean = false;
  loadingPanelVisible: boolean = false;
  todayDate: any;
  todayDateTime: any;
  public pageSize = 10;
  public skip = 0;
  title = "Audit Report";
  
  messageStatusData: any = [
    { id: "All", value: "All" },
    { id: "Hold", value: "Hold" },
    { id: "Ready", value: "Ready" },
    { id: "Sent", value: "Sent" },
    { id: "Failed", value: "Failed" },
   
  ]
  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public buttonCount = 5;
  public info = true;
  public pageSizes = [20,50,100,200];
  public previousNext = true;
  public position: PagerPosition = "bottom";
  isTablet: boolean = false;

  pageOn: boolean = false;
  constructor(private datePipe: DatePipe,
    private patientService: PatientService,
    private auditServices: AuditService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
  private router:Router,
private dashboardService: DashboardStoreService) {
    this.messageReportFilterForm = this.formBuilder.group({
      status: ['', Validators.required],
      fromDate: [''],
      toDate: [''],
      tenantId: ['']
    })
        this.checkDevice();
    
      }
    
      @HostListener('window:resize', ['$event'])
      onResize(event: any) {
        this.checkDevice();
      }
    
      checkDevice() {
        const width = window.innerWidth;
        this.isTablet = width >= 768 && width <= 1024; // Adjust based on your tablet breakpoints
      }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
  }

  ngOnInit(): void {
    this.auditServices.writeOparationalLog("Access Audit Report#")
    this.commonService.setSessionStorage("redirectTo", JSON.stringify('auditreport'));
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if(this.router.url == '/clinic-dashboard') 
        this.pageOn = true;

    // this.setDefaultFilter();
    this.tenantId = this.commonService.getSessionStorage('tnt');
    this.getAuditData()

    
  }

  getAuditData() {
    this.dashboardService.auditObservable$.subscribe((data) => { 
      this.messageReportFilterForm.patchValue({
        status: data.status?.name,
        fromDate: data.startDate,
        toDate: data.endDate,
        tenantId: this.tenantId
      }) 
      this.applyFilter();
    })
  }

  isSidebarVisible: boolean = false;

  onSidebarToggle(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
  setDefaultFilter() {
    this.messageReportFilterForm.get('fromDate')!.setValidators([Validators.required]);
    this.messageReportFilterForm.get('toDate')!.setValidators([Validators.required]);
    this.fromDate = new Date(), 'MM/dd/yyyy';
    this.toDate = new Date((new Date()).valueOf() + 1000 * 3600 * 24 + (1000 * 3600 * 24)), 'MM/dd/yyyy'; // +2 day
    this.tenantId = this.commonService.getSessionStorage('tnt');
    this.messageReportFilterForm.patchValue({
      status: "All",
      fromDate: this.fromDate,
      toDate: this.toDate,
      tenantId: this.tenantId
    });
    this.range.start = this.fromDate;
    this.range.end = this.toDate;
    this.isDateRangeError = false;
    this.isResetDisable = true;
    this.isDateRangeValid = true;
   // this.isDateRangeVisible = (this.messageReportFilterForm.controls['status'].value === "Sent" || this.messageReportFilterForm.controls['status'].value === "Failed") ? true : false;
    this.getAuditData();
  }

  statusValueChange(event: any) {
    this.isSkipPage = false;
   // this.isDateRangeVisible = (event === "Sent" || event === "Failed") ? true : false;
    const fromDate = this.messageReportFilterForm.get('fromDate');
    const toDate = this.messageReportFilterForm.get('toDate');
    fromDate?.setValidators([Validators.required]);
    toDate?.setValidators([Validators.required]);
    // if (event == "Sent" || event == "Failed") {
    //   fromDate?.setValidators([Validators.required]);
    //   toDate?.setValidators([Validators.required]);
    // } else {
    //   // fromDate?.clearValidators();
    //   // toDate?.clearValidators();
    //   // this.isDateRangeError = false;
    //   // if (this.messageReportFilterForm.controls['fromDate'].value == null || this.messageReportFilterForm.controls['toDate'].value == null) {
    //   //   this.messageReportFilterForm.patchValue({
    //   //     fromDate: '',
    //   //     toDate: '',
    //   //   });
    //   // }
    // }
    if (this.messageReportFilterForm.controls['status'].value != null || this.messageReportFilterForm.controls['fromDate'].value != null || this.messageReportFilterForm.controls['toDate'].value != null) {
      this.isResetDisable = true;
    }
    this.isDateRangeValid = true;
  }

  fromDateValueChange(fromDate: any) {
    this.isDate = true;
    this.isSkipPage = false;
    this.range.start = fromDate;
    if (this.datePipe.transform(this.range.start, 'yyyy-MM-dd') > this.datePipe.transform(this.range.end, 'yyyy-MM-dd')) {
      this.isDateRangeError = true;
    } else {
      this.isDateRangeError = false;
    }
    if (this.range.start === null || this.range.end === null) {
      this.isDateRangeValid = this.range.start === null && this.range.end === null;
    } else {
      this.isDateRangeValid = true;
    }
  }

  toDateValueChange(toDate: any) {
    this.range.end = toDate;
    this.isSkipPage = false;
    if (this.datePipe.transform(this.range.start, 'yyyy-MM-dd') > this.datePipe.transform(this.range.end, 'yyyy-MM-dd')) {
      this.isDateRangeError = true;
    } else {
      this.isDateRangeError = false;
    }
    if (this.range.start === null || this.range.end === null) {
      this.isDateRangeValid = this.range.start === null && this.range.end === null;
    } else {
      this.isDateRangeValid = true;
    }
  }

  applyFilter() {
    //check
     this.todayDateTime = new Date();
    if (this.isSkipPage == false) {
      this.skip = 0;
    }
    this.loadingPanelVisible = true;
    let payload = this.messageReportFilterForm.value;
    this.messageFilter.status = payload?.status;
    this.messageFilter.usertimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.isActionVisible = false;
      this.messageFilter.fromdate = this.datePipe.transform(payload?.fromDate, 'yyyy-MM-dd') ? this.datePipe.transform(payload?.fromDate, 'yyyy-MM-dd') : "";
      this.messageFilter.todate = this.datePipe.transform(payload?.toDate, 'yyyy-MM-dd') ? this.datePipe.transform(payload?.toDate, 'yyyy-MM-dd') : "";
    // if ((this.messageFilter?.status === "Sent" || this.messageFilter?.status === "Failed")) {
    //   this.isActionVisible = false;
    //   this.messageFilter.fromdate = this.datePipe.transform(payload?.fromDate, 'yyyy-MM-dd') ? this.datePipe.transform(payload?.fromDate, 'yyyy-MM-dd') : "";
    //   this.messageFilter.todate = this.datePipe.transform(payload?.toDate, 'yyyy-MM-dd') ? this.datePipe.transform(payload?.toDate, 'yyyy-MM-dd') : "";
    // } else {
    //   // this.messageFilter.fromdate = "";
    //   // this.messageFilter.todate = "";
    //   // this.isActionVisible = true;
    // }
    this.messageFilter.tenantid = this.tenantId;
    this.patientService.getMessageDetails(this.messageFilter).subscribe((messageDetailsRes: any) => {
      if (messageDetailsRes) {
        this.loadingPanelVisible = false;
        messageDetailsRes.map((m) => {
          m.apttdate = m?.apttdate + " " + m?.aptttime;
          const dateParts = m?.apttdate.split(' ');
          const date = dateParts[0];
          const time = dateParts[1];
          const meridian = dateParts[2];
          const dateComponents = date.split('-').map(Number);
          let timeComponents = time.split(':').map(Number);
          if (meridian === 'PM' && timeComponents[0] !== 12) {
            timeComponents[0] += 12;
          } else if (meridian === 'AM' && timeComponents[0] === 12) {
            timeComponents[0] = 0;
          }
          const [year, month, day] = dateComponents;
          const [hours, minutes] = timeComponents;
          const dateObject = new Date(year, month - 1, day, hours, minutes);
          if (dateObject.getTime() > this.todayDateTime.getTime()) {
            m.isHoldReady = true;
          } else {
            m.isHoldReady = false;
          }
          if (m?.reason) {
            m.reason = m?.reason.toLowerCase();
          }
        })
        this.messageDetailsData = messageDetailsRes;
        this.loadMessageDetails();
      } else {
        this.loadingPanelVisible = false;
      }
    })
  }

  loadMessageDetails() {
    if (this.messageDetailsData?.length != 0) {
      this.isPage = true;
    }
    this.gridMessageDetails = {
      data: this.messageDetailsData.slice(this.skip, this.skip + this.pageSize),
      total: this.messageDetailsData.length,
    };
    this.gridMessageDetails.data = this.gridMessageDetails.data.map((item: any) => {
      let dateParts = item.msgSentDate.split("-");
      let parsedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      parsedDate.setDate(parsedDate.getDate());
       let newDate = parsedDate.toISOString().split('T')[0];
      item.msgSentDate = newDate
      return item;
    });
    
     setTimeout(() => {
      this.loadingPanelVisible = false;
    }, 600);
  }

 public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.loadMessageDetails();
  }

  clearFilter() {
    this.setDefaultFilter()
  }

  updateMessageStatus(status: string, sessionid: string) {
    this.isSkipPage = true;
    let payLoad = {
      tenantid: this.tenantId,
      sessionid: sessionid,
      status: status
    }
    this.patientService.updateMessageStatus(payLoad).subscribe((res: any) => {
      if (res) {
        this.auditServices.writeOparationalLog("Edit appointment status#" + status);
        this.applyFilter();
      }
    })
  }

  exportUserList(): void {
    if (this.isPage == true) {
      const header: string[] = ["Patient Name", "Patient Code", "Appointment Date", "Status", "Reason", "Message Content", "Message Sent On"];
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Audit Report");
      const headerRow = worksheet.addRow(header);
      headerRow.font = {
        family: 4,
        size: 11,
        bold: true,
      };
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffffff" },
          bgColor: { argb: "ffffff" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
      this.messageDetailsData.forEach((d) => {
        let newArr = [];
        let lastname = d?.lastname.toUpperCase();

        newArr[0] = `${lastname}, ${d?.firstname} ${d?.preferredname ? `"${d?.preferredname}" ` : ''} ${d?.middlename ? `${d?.middlename[0]}.` : ''}`;
        const patientCode: number = +d?.patientcode;
        newArr[1] = patientCode ? patientCode : '';
        newArr[2] = `${d?.apttdate ? `${d?.apttdate} ` : ''}`;
        newArr[3] = d?.status;
        newArr[4] = d?.reason ? d?.reason : '';
        newArr[5] = `${d?.textmessage ? `${d?.textmessage}` : ''} `;
        newArr[6] = `${d?.msgSentDate ? `${d?.msgSentDate} ` : ''} ${d?.msgSentTime ? `${d?.msgSentTime}` : ''} `;

        const row = worksheet.addRow(newArr);
        row.eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffffff" },
            bgColor: { argb: "ffffff" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        })
      });

      worksheet.getColumn(1).width = 30;
      worksheet.getColumn(2).width = 15;
      worksheet.getColumn(3).width = 25;
      worksheet.getColumn(4).width = 15;
      worksheet.getColumn(5).width = 20;
      worksheet.getColumn(6).width = 75;
      worksheet.getColumn(7).width = 25;
      workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const current = new Date();
        const timestamp = current.getTime();
        let fileName = "Message Audit Report " + timestamp;
        fs.saveAs(blob, fileName + `.xlsx`);
      });
    }
  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === "TD" || element.className === "k-column-title") &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  navigateToDashboard(){
    this.router.navigate(['/clinic-dashboard'])
    sessionStorage.removeItem('redirectTo')
  }

  activeTab: string = 'appointment';
  isVisibleUsage = true;
  isVisibleAudit = true;
 
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
}
