<div class="pos-relative">
<div *ngIf="loadingPanelVisible" class="k-i-loading loading-overlay"></div>
<div *ngIf="loadingPanelVisible1" class="k-i-loading loading-overlay"></div>

<div class="clinic_dashboard_patient_list_main ">
  <div class="d-flex borderBottom20 align-items-center bg-lightgray">
    <div class="w-100">
      <div class="d-flex align-items-center justify-content-center">
        <ion-button (click)="decreaseDate()" color="highlight" fill="clear">
          <ion-icon class="font20 pointer" color="highlight" src="../../../../assets/svg/chevron-left.svg"></ion-icon>
        </ion-button>

        <div class="ion-text-center column pointer" id="cal-date-popup">
          <ion-text class="bodyM-txt-bold" color="gray80"> {{ currentDate | date:'EEEE' }}, {{ currentDate | date:'MMM
            d, yyyy' }}</ion-text>
        </div>

        <ion-button (click)="increaseDate()" color="highlight" fill="clear">
          <ion-icon class="font20 pointer" color="highlight"
            src="../../../../../../assets/svg/chevron-right 1.svg"></ion-icon>
        </ion-button>
      </div>
    </div>
    <div>
      <ion-button color="highlight" fill="clear">
        <ion-icon class="mr-5" src="../../../../../../../assets/svg/today.svg" color="highlight"></ion-icon>
        <ion-text (click)="displayToday()" class="bodyM-txt-regular" color="hightlight">TODAY</ion-text>
      </ion-button>
    </div>
  </div>
  <div class="d-flex borderBottom20 p-10 align-items-center">
    <ion-searchbar placeholder="Search Patients" mode="q" (ionClear)="clear()" (ionClear)="log()"
      #searchPatient></ion-searchbar>
    <ion-icon class="bodyXL-txt ml-10 pointer" color="gray80" src="../../../../../../assets/svg/filter.svg"
      id="patient-fliter"></ion-icon>
  </div>
  <ion-popover class="clinic_list_popup" trigger="patient-fliter" side="bottom" alignment="end" dismiss-on-select="true"
    show-backdrop="false" style="--offset-x: -0.65rem; --offset-y:0.5rem">
    <ng-template>
      <div class="clinic_location_list d-flex align-items-center px-10 pointer" (click)="toggleCheckbox('All')">
        <input type="checkbox" kendoCheckBox value="All" [checked]="allChecked" />
        <kendo-label class="k-checkbox-label" text="All"></kendo-label>
      </div>
      <!-- <div class="clinic_location_list d-flex align-items-center px-10 pointer" (click)="toggleCheckbox('Pre-registered')">
        <input type="checkbox" kendoCheckBox value="Pre-registered" [checked]="preRegisteredChecked" />
        <kendo-label class="k-checkbox-label" text="Pre-registered"></kendo-label>
      </div>
      <div class="clinic_location_list d-flex align-items-center px-10 pointer" (click)="toggleCheckbox('Not-registered')">
        <input type="checkbox" kendoCheckBox value="Not-registered" [checked]="notRegisteredChecked"/>
        <kendo-label class="k-checkbox-label" text="Not-registered"></kendo-label>
      </div> -->

      <div class="clinic_location_list d-flex align-items-center px-10 pointer" (click)="toggleCheckbox('In-progress')">
        <input type="checkbox" kendoCheckBox value="In-progress" [checked]="inprogresschecked" />
        <kendo-label class="k-checkbox-label" text="In-progress"></kendo-label>
      </div>

      <div class="clinic_location_list d-flex align-items-center px-10 pointer" (click)="toggleCheckbox('Approved')">
        <input type="checkbox" kendoCheckBox value="Approved" [checked]="approvedchecked" />
        <kendo-label class="k-checkbox-label" text="Approved"></kendo-label>
      </div>

      <div class="clinic_location_list d-flex align-items-center px-10 pointer" (click)="toggleCheckbox('Completed')">
        <input type="checkbox" kendoCheckBox value="Completed" [checked]="completedchecked" />
        <kendo-label class="k-checkbox-label" text="Completed"></kendo-label>
      </div>
    </ng-template>
  </ion-popover>

  <div class="patient_list_main listMain" *ngIf="!isNoResult && !loadingPanelVisible1;">
    <div class="patient_list d-flex align-items-center justify-content-sb px-15 py-5 borderBottom05 cursor"
      *ngFor="let patient of patientAll; let i=index" (click)="patientDetails(patient)">
      <div class="d-flex align-items-center w-70">
        <ion-avatar class="small-avatar" slot="start">
          {{patient.patientlastname | initials }}{{patient.patientfirstname | initials}}
        </ion-avatar>
        <div class="">
          <div class="line-16">
            <ion-text class="font16 text-blue-color patNameEllipse" color="text-blue-color">
              <b>{{patient.patientlastname | uppercase }},</b> {{patient.patientfirstname | titlecase}}
              <span *ngIf="!(patient.preferredname == null || patient.preferredname == '')">
                "{{patient.preferredname | titlecase}}"

              </span>

              <span *ngIf="!(patient.patientmiddlename == null || patient.patientmiddlename == '')">
                {{patient.patientmiddlename| initials}}.

              </span> </ion-text>
          </div>
          <div class="line-15 d-block align-items-center flex-wrap">
            <ion-text class="font13-italic" color="gray80">{{patient.patientdtdob | calculateAge}}y {{patient.gender |
              initials}} </ion-text>
            <ion-text class="bodyXS-txt mx-5" color="gray20">|</ion-text>
            <ion-text class="font13-italic" color="gray80"> {{patient.patientdtdob | dateFormat}}</ion-text>
            <ng-container *ngIf="!(patient.appointmenttypedesc == null || patient.appointmenttypedesc == '')">
              <ion-text class="bodyXS-txt mx-5" color="gray20">|</ion-text>
              <ion-text class="font13-italic" color="gray80">{{patient.appointmenttypedesc}} </ion-text>
            </ng-container>
            <ion-text class="bodyXS-txt mx-5" color="gray20" *ngIf="patient?.state">| </ion-text>
            <ion-text class="font13 pointer white-space-wrap" color="gray80"> {{patient?.state}}</ion-text>
            <!-- Added by UI/UX team -->
            <ion-text class="bodyXS-txt" *ngIf="patient?.status ==='Pre-registered'" color="gray20"></ion-text>
            <!-- <ion-text class="bodyXS-txt mx-5"  *ngIf="patient?.status ==='Pre-registered'"  color="gray20">|</ion-text> -->
            <!-- <ion-text class="bodyXS-txt mx-5" color="gray20">|</ion-text> -->
            <!-- self register icon added -->
            <!-- <ion-icon class="intake-icon" src="../../../../../../assets/svg/self_registered.svg"></ion-icon>
            <ion-text class="bodyXS-txt mx-5" color="gray20" *ngIf="preRegisterPatientList.includes(patient.appointmentdetailsid)">|</ion-text> -->

            <!-- <ion-text class="font13-italic-regular pointer"  *ngIf="patient?.status === 'Pre-registered'"  color="success100" > Pre-registered </ion-text>  -->
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center w-30 justify-content-end">
        <div class="mr-15">
          <div class="line-13 ion-text-right">
            <ion-text class="caption" color="gray80">{{patient.appointmentdate | date: 'shortTime'}} </ion-text>
          </div>
          <div class="line-15 text-right">
            <ion-text class="caption" color="gray80">{{ patient.providername }}</ion-text>
          </div>
        </div>
        <div>

          <ion-icon src="../../../../../../assets/svg/D-chevron-right.svg"></ion-icon>
        </div>
      </div>
    </div>
  </div>
  <ng-template>
    <div class="noPatient">
      Not Patient Found

    </div>
  </ng-template>
  <div *ngIf="!loadingPanelVisible ||  !loadingPanelVisible1">
    <app-clinic-dashboard-search-noresult *ngIf="isNoResult && !loadingPanelVisible && !loadingPanelVisible1"
      [search]="'patient'" [searchTerm]="searchTerm" #noPatient></app-clinic-dashboard-search-noresult>
  </div>
</div>
</div>

<!-- Accepted Popup -->
<kendo-dialog class="accepetedPopup"  *ngIf="isAccepted || notUpdated">
  <div class=""  *ngIf="notUpdated">
    <div class="note-box px-20 py-15 text-center"> 
      <ion-text *ngIf="notUpdated" class="h3-Montserrat" color="gray100">
        This patient has not updated any demographics and insurance information
      </ion-text>
    </div>
    <div class="btn-box w-100 text-center px-50 py-20">
      <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize" (click)="closeAccepted()">
        <ion-text>Acknowledge</ion-text>
      </ion-button>
    </div>
  </div>
  <div class=""  *ngIf="isAccepted">
    <div class="note-box px-20 py-15 text-center">
      <ion-text *ngIf="isAccepted" class="h3-Montserrat" color="gray100">
        All the changes have been already accepted
      </ion-text> 
    </div>
    <div class="btn-box w-100 text-center px-50 py-20">
      <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize" (click)="closeApproved()">
        <ion-text>OK</ion-text>
      </ion-button>
    </div>
  </div>
</kendo-dialog>

<ion-popover [keepContentsMounted]="true" trigger="cal-date-popup" class="ApptcalenderPopover customionpopover"
  side="bottom" alignment="center" popover-animation="true" arrow="false">
  <ng-template>
    <div class="calender-section">
      <kendo-calendar #calendar type="classic" (valueChange)="onDateSelected($event)"></kendo-calendar>
    </div>
  </ng-template>
</ion-popover>
