<div class="no_patient_found mt-20">
  <div class="no_patient_icon">
    <ion-icon src="../../../../assets/svg/search_orange.svg" class="ion-color-secondary100" color="secoundry100"></ion-icon>
  </div>
  <div class="text-center mt-10">
    <ion-text color="gray100" class="bodyS-txt-bold d-block mb-5">We couldn’t find any {{search}} <span *ngIf="searchTerm != ''">for search `{{searchTerm}}`</span> </ion-text>
    <ion-text class="bodyXS-txt" color="gray100" *ngIf="searchTerm != ''">Check for spelling or try searching for another term</ion-text>
    <!-- <ion-text class="bodyXS-txt" color="gray100" *ngIf="searchTerm == ''">Check for location or provider</ion-text> -->

  </div>

  <!-- <div class="text-center mt-100">
    <ion-text color="gray80" class="bodyM-txt-light">No appointments on this day</ion-text>
  </div> -->
</div>