import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { DashboardLocationModel } from 'src/app/core/models/dashboardLocationModel';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';
import { PatientService } from 'src/app/core/services/patient.service'; 

@Component({
  selector: 'app-clinic-dashboard-sidebar',
  templateUrl: './clinic-dashboard-sidebar.component.html',
  styleUrls: ['./clinic-dashboard-sidebar.component.scss'],
})
export class ClinicDashboardSidebarComponent implements OnInit {

  constructor(private commonService: CommonService,
    private dashboardService: DashboardStoreService,
    private patientService: PatientService,
    private fb: FormBuilder,
  private router: Router) { }

  auditSelected: string = "";
  range: any = { start: null, end: null };
  hasAppointmentPermission: boolean = true;
  hasUsageReportPermission: boolean = true;
  hasAuditReportPermission: boolean = true;

  cancelBtn: boolean = false;

  tenantid: any;
  locationid: any;
  filterForm: FormGroup = this.fb.group({
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    status: [null, Validators.required],
    appointmentType: [null, Validators.required],
    completionMode: [null, Validators.required],
    location: [[], Validators.required]
  });
  auditReportForm: FormGroup = this.fb.group({
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    status: [null, Validators.required],
  });
  selectedLocations: any[] = [];
  appointmentDataLoadad: boolean = false;


  public StatusUsage: Array<any> = [
    {
      name: 'All',
      id: 1
    },
    {
      name: 'In-Progress',
      id: 2
    },
    {
      name: 'Completed',
      id: 3
    },
    {
      name: 'Approved',
      id: 4
    } 
  ];

  
  public StatusAudit: Array<any> = [
    {
      name: 'All',
      id: 1
    },
    {
      name: 'Hold',
      id: 2
    },
    {
      name: 'Ready',
      id: 3
    },
    {
      name: 'Sent',
      id: 4
    },
    {
      name: 'Failed',
      id: 5
    }
  ];

  public ApptType: Array<any> = [
    {
      name: 'Appointment type 1',
      id: 1
    },
    {
      name: 'Appointment type 2',
      id: 2
    },
    {
      name: 'Appointment type 3',
      id: 3
    },
    {
      name: 'Appointment type 4',
      id: 4
    },
    {
      name: 'Appointment type 5',
      id: 5
    }
  ];

  public Completion: Array<any> = [
    {
      name: 'Mobile',
      id: 1
    },
    {
      name: 'Tablet',
      id: 2
    },
    
  ];


  @Input() activeTab!: string; // Gets the active tab from the parent
  @Output() tabChange = new EventEmitter<string>(); // Sends event when tab changes


  ngOnInit() {
    let userRole = this.commonService.getSessionStorage('roles');
    this.tenantid = this.commonService.getSessionStorage('tnt');
    if (!userRole?.includes("Clinic Dashboard")) {
      this.hasAppointmentPermission = false;
    } 
    if (!userRole?.includes("Audit Report")) {
      this.hasUsageReportPermission = false; 
      this.hasAuditReportPermission = false;
    }
    this.loadData();
    this.setDefaultAuditForm()
    this.setDefaultUsageForm()  
  }

  setDefaultAuditForm() { 
    var dDate: Date = new Date();
     this.auditReportForm.patchValue({
      startDate: dDate,
      endDate: dDate,
      status: {
        name: 'All',
        id: 1
      }
    });
    this.dashboardService.auditReportBehaviour.next(this.auditReportForm.value);
  }

   setDefaultUsageForm() { 
    var dDate: Date = new Date();
     this.filterForm.patchValue({
      startDate: dDate,
      endDate: dDate,
      status: {
        name: 'All',
        id: 1
      },
      appointmentType: {
        name: 'All',
        id: 1
      },
      completionMode: {
        name: 'Mobile',
        id: 1
      },
      location: []
    });
    this.dashboardService.usageReportBehaviour.next(this.filterForm.value);
   }


  loadData() {

    if (this.appointmentDataLoadad)
      return;
     this.dashboardService.getLocationByTenantId(this.tenantid).subscribe({
      next: this.getLocationResponseByHandler.bind(this)
    })
  }

  getLocationResponseByHandler(object: DashboardLocationModel[]) {
    this.listItems = object.map(location => location.locationdescription);
    this.fetchAppointmentTypes()
   }


  changeTab(tab: string) {
    this.tabChange.emit(tab); // Emit selected tab to Dashboard
  }

  showDateCancel() {
    this.cancelBtn = true;
  }
  clearDate() {
    // this.clearRange.setRange({
    //   start: new Date(0),
    //   end: new Date(0)
    // });
    this.range = { start: null, end: null };

    this.cancelBtn = false;
  }

  listItems = ['Paris', 'Pune', 'Main Location', 'Mumbai', 'Nashik'];
  value: string[] = [];

  // activeTab: string = 'appointment';
  isVisibleUsage = false;
  isVisibleAudit = false;

  showTab(tab: string) {
    this.activeTab = tab; // Set active tab dynamically
    this.tabChange.emit(tab);
     // condition for isvisibleusage and isvisibleaudit on basis of tab
    if (tab === 'usage-report') {
      this.isVisibleUsage = !this.isVisibleUsage;
      this.isVisibleAudit = false;
    } else if (tab === 'audit-report') {
      this.isVisibleAudit = !this.isVisibleAudit;
      this.isVisibleUsage = false;
    } else {
      this.isVisibleUsage = false;
      this.isVisibleAudit = false;
    }
    this.navigateToUrl(this.activeTab);
    //if router link is usage-report then show usage report

  }

  selectedTab: string = 'appointment';

  selectTab(tabName: string) {
    this.selectedTab = tabName;
    this.navigateToUrl(this.selectedTab);
  } 

  navigateToUrl(tabName: string) {
    if(this.router.url !== '/clinic-dashboard'){
      if(tabName == 'appointment'){
        this.router.navigate(['/clinic-dashboard']);
      }else if(this.selectedTab == 'usage-report'){
        this.router.navigate(['/app/usage-report']);
      }else if(this.selectedTab == 'audit-report'){
        this.router.navigate(['/app/audit-report']);
      } 
    }
  }

  fetchAppointmentTypes() {  
     let obj = { "tenantid": [this.tenantid] }
      if (this.tenantid )
        this.patientService.getAllQIntakeAppointments(obj).subscribe(
          (data: any) => {
            this.ApptType = data;
            this.appointmentDataLoadad = true; 

          },
          (error: any) => {
            console.error('Error fetching appointment types', error);
          }
        ); 


  }
  filterUsage!: any;
  applyFilter() {
    this.filterUsage = this.filterForm.value;
     this.dashboardService.usageReportBehaviour.next(this.filterUsage);
  }

  resetUsageForm() {
    this.filterForm.reset();
    this.selectedLocations = [];
  }

  applyFilterAudit() {
    this.filterUsage = this.auditReportForm.value;
    this.dashboardService.auditReportBehaviour.next(this.filterUsage);
  }

  resetAuditForm() {
    this.auditReportForm.reset();
  }
}
