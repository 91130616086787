import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { take } from 'rxjs';
import { DashboardLocationModel } from 'src/app/core/models/dashboardLocationModel';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { LoginService } from 'src/app/core/tablet-service/login.service';
import { environment } from 'src/environments/environment';
import { LogoutAvatarComponent } from './logout-avatar/logout-avatar.component';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-clinic-dashboard-header',
  templateUrl: './clinic-dashboard-header.component.html',
  styleUrls: ['./clinic-dashboard-header.component.scss'],
})
export class ClinicDashboardHeaderComponent implements OnInit {

  location: Array<any> = [];
  selectedLocation: DashboardLocationModel = {
    locationid: "",
    locationdescription: "",
    city: "",
    state: "",
    zip: "",
    addressline1: "",
    addressline2: "",
    isblocked: false,
    tenantid: "",
    isdefault: false,
    isChecked: false
  };
  image: any = {
    username: "",
    url: "",
    firstname: "",
    lastname: ""
  }
  imgVal: string = '';
  errorLoading: boolean = false;
  tenantLength: any;
  showHide: boolean = false;
  currentRoute: string = '';
  @Input() public title: string;
  @Input('usageTitle') usagetitle:any;
  usageSelected: string = "";
  auditSelected: string = "";
  cancelBtn: boolean;
   apptSelected: string = "tab-selected";
  allLocations !: any;
  hasAppointmentPermission: boolean = true;
  hasUsageReportPermission: boolean = true;
  hasAuditReportPermission: boolean = true;
  dismissOnPageChange: string = 'true';
  constructor(
    private loginService: LoginService,
    private patientService: PatientService,
    private dashboardService: DashboardStoreService,
    private router: Router,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private popOverCtrl: PopoverController,
    private auditService: AuditService,
    private fb: FormBuilder,
    private deviceService: DeviceDetectorService
  ) {
    this.location = [];
  }

  tenantName: string = '';
  ngOnInit() {
    let urlResponse = '';
    this.showHide = this.deviceService.isTablet()  ;
    this.activatedRoute.url.subscribe({
      next: (response: any) => {
        if (response[0]?.path == 'clinic-dashboard')
          this.showHide = false
        else
          this.showHide = true
      }
    })
    this.activatedRoute.params.subscribe(() => {
      this.getTenantIds();
    });
    this.getTenants()
    this.activatedRoute.url.subscribe({
      next: (response: any) => {
        this.currentRoute = response.map((item: any) => item.path).join('/')
      }
    })
    this.tenantName = this.commonService.getSessionStorage('TN')
    // this.getTenantIds();
    this.filterForm = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      status: [null, Validators.required],
      appointmentType: [null, Validators.required],
      completionMode: [null, Validators.required],
      location: [[], Validators.required]
    });
    this.auditReportForm = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      status: [null, Validators.required], 
    });
    sessionStorage.removeItem('redirectTo');
    this.tenantid = this.commonService.getSessionStorage("tnt");
    this.locationid = this.commonService.getLocalStorage('location');
     this.loadAPIForReport()
    
  }
  showIcon: boolean = true;
  loadAPIForReport() {
    if(this.appointmentTypesLoaded)
      return;

    let userRole = this.commonService.getSessionStorage('roles');
    if (! userRole?.includes("Clinic Dashboard")) {
      this.hasAppointmentPermission = false;
    } 
    if (! userRole?.includes("Audit Report")) {
      this.hasUsageReportPermission = false; 
      this.hasAuditReportPermission = false;
    }

    if (userRole?.includes("Clinic Dashboard") &&  !userRole?.includes("Audit Report"))  {
      this.showIcon = false;
    }
    this.fetchAppointmentTypes()
    this.dashboardService.getLocationByTenantId(this.tenantid).subscribe({
      next: this.getLocationResponseByHandler.bind(this)
    })
  } 


  getTenants() {
    var piDetails = this.commonService.getSessionStorage('PI');
    var token = piDetails.token;
    var uid = this.commonService.getSessionStorage('UID');
    this.loginService.getQoreUserDetails(uid, token).subscribe((response: any) => {
      if (response) {
        this.tenantLength = response.tenants
      }
    });

  }


  getTenantIds() {
    //let tenant = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('tenant'))));

    let tenant = this.commonService.getSessionStorage('tnt');
    // console.log('tennathead', tenant);
    if (tenant != null) {
      //let imageUrl = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('url'))));
      let imageUrl = this.commonService.getSessionStorage('url');
      this.image = imageUrl;
      this.loadImage(this.image.url);
      let alltenant: Array<string> = []
      if (tenant != '')
        // tenant.map((item: any) => alltenant.push(item.tenantid))
        //console.log('tenantid',tenant);
        // tenant.map((item: any) => alltenant.push(item.tenantid))
        // this.dashboardService.getLocationByTenantId(alltenant.toString()).subscribe({
        //   next: this.getLocationResponseByHandler.bind(this)
        this.dashboardService.getLocationByTenantId(tenant).subscribe({
          next: this.getLocationResponseByHandler.bind(this)
        })
    } else {
      setTimeout(() => this.getTenantIds(), 10)
    }
  }

  getLocationResponseByHandler(object: DashboardLocationModel[]) {
    this.location = object;
     this.listItems = object.map(location => location.locationdescription);
    this.filterEnabledLocations();
    this.tenantName = this.commonService.getSessionStorage('TN')
    // if (this.commonService.getLocalStorage('location') && this.commonService.getLocalStorage('location')?.locationid && object.length && object.some((item: DashboardLocationModel) => item.locationid === this.commonService.getLocalStorage('location')?.locationid)) {
    //   object.map((item: DashboardLocationModel) => {
    //     if (item.locationid === this.commonService.getLocalStorage('location').locationid) {
    //       item.isChecked = true
    //       this.selectedLocation = item;
    //     }
    //   })
    // }
    // else {
    //   this.selectedLocation =object.filter((item: DashboardLocationModel) => item.isdefault)[0]
    //   object.map((item: DashboardLocationModel) => { if (item.isdefault) { item.isChecked = true } })
    // }
    // this.dashboardService.locationBehaviour.next([this.selectedLocation])
  }

  filterEnabledLocations() {
    const tenant = this.commonService.getSessionStorage('tnt');
    this.dashboardService.getEnabledLocationByTenantId(tenant).subscribe((enabledLocations: any[]) => {
      // Filter locations based on enabled locations
      this.location = this.location.filter(loc => 
        enabledLocations?.some(enabledLoc => enabledLoc.locationid === loc.locationid)
      );
  
      // Verify stored location
      this.verifyStoredLocation();
      
      // If there is a valid stored location in localStorage
      const storedLocation = this.commonService.getLocalStorage('location');
      if (storedLocation && storedLocation.locationid && this.location.length && 
          this.location.some(item => item.locationid === storedLocation.locationid)) {
        this.location.forEach(item => {
          if (item.locationid === storedLocation.locationid) {
            item.isChecked = true;
            this.selectedLocation = item;
          }
        });
      } else {
        // Select the default location or the first item if no default is available
        this.selectedLocation = this.location.find(item => item.isdefault) || this.location[0];
        
        if (this.selectedLocation) {
          this.selectedLocation.isChecked = true;
        }
      }
  
      this.dashboardService.locationBehaviour.next([this.selectedLocation]);
    });
  }
  
  // filterEnabledLocations() {
  //   const tenant = this.commonService.getSessionStorage('tnt');
  //   this.dashboardService.getEnabledLocationByTenantId(tenant).subscribe((enabledLocations: any[]) => {
  //     console.log('Enabled locations:', enabledLocations);
  //     this.location = this.location.filter(loc => enabledLocations.some(enabledLoc => enabledLoc.locationid === loc.locationid));
  //     console.log("this.location", this.location)
  //    // this.selectedLocation = this.location.filter((item: DashboardLocationModel) => item.isdefault)[0];
  //    this.verifyStoredLocation()
  //     if (this.selectedLocation) {
  //       this.selectedLocation.isChecked = true;
  //     } else if (this.location.length > 0) {
  //       this.selectedLocation = this.location[0];
  //       this.selectedLocation.isChecked = true;
  //     }
  //     this.dashboardService.locationBehaviour.next([this.selectedLocation])
  //   });

  // }

  verifyStoredLocation() {
    const storedLocation = this.commonService.getLocalStorage('location');
  
    if (storedLocation && storedLocation.locationid) {
      const exists = this.location.some(loc => loc.locationid === storedLocation.locationid);
      if (exists) {
        this.selectedLocation = this.location.find(loc => loc.locationid === storedLocation.locationid);
        this.selectedLocation.isChecked = true;
      } else {
        localStorage.removeItem('location');
        this.selectedLocation = null;
      }
    }
    // Check if no selected location is set, then select the first item
    if (!this.selectedLocation && this.location.length > 0) {
      this.selectedLocation = this.location[0];
      this.selectedLocation.isChecked = true;
      // Update the local storage with the new selected location
      this.commonService.setLocalStorage('location', this.selectedLocation);
    }
  }
  
  
  changeLocation(event: any, locationSel: DashboardLocationModel) {
    // this.location = 
    this.commonService.setLocalStorage('location', locationSel)
    this.location.forEach((item: DashboardLocationModel) => item.isChecked = false)
    locationSel.isChecked = true
    this.selectedLocation = locationSel

    //console.log('locationSel', locationSel);
    this.auditService.writeOparationalLog('change location#' + locationSel.locationid + "#" + locationSel.locationdescription);
    this.dashboardService.locationBehaviour.next([locationSel])
  }

  loadImage(urls: string) {
    let url = environment.otc_url + 'getSignUrl?medialink=' + urls
    this.dashboardService.getImage(url).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response && response.base64Content) {
          if (response.base64Content != "") {
            this.imgVal = 'data:image/png;base64, ' + response.base64Content
            this.errorLoading = true;
          }
          else
            this.errorLoading = false;
        }
      },
      error: (error: any) => {
        this.errorLoading = false;
      }
    })
  }

  navigateToLogin() {
    // sessionStorage.removeItem("returnLocation")
    this.auditService.writeOparationalLog('LockScreen#' + 'ClinicDashboardHeaderComponent');
    sessionStorage.removeItem('PI');
    sessionStorage.removeItem('url');
    sessionStorage.removeItem('UID');
    sessionStorage.removeItem('sid');
    sessionStorage.removeItem('uifo');
    sessionStorage.removeItem('tid');
    sessionStorage.removeItem('pid');
    sessionStorage.removeItem('isVerified');
    //sessionStorage.clear()
    // console.log(this.currentRoute)
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    let returnLoc = JSON.stringify({ loc: this.currentRoute, check: true })
    this.commonService.setSessionStorage("returnLocation", returnLoc)
    this.router.navigate(['/clinic'])
  }

  logout() {
    this.loginService.logout()
    sessionStorage.removeItem('returnLocation')
    // sessionStorage.clear()
    // this.router.navigate(['/clinic'])
  }
  removetenant() {
    //sessionStorage.removeItem('tenant')
    this.location = [];
    this.dashboardService.locationBehaviour.next(this.location)
    this.router.navigate(['/practice-list']);
  }

  async popOver(event: any) {
    const popOvers = await this.popOverCtrl.create({
      component: LogoutAvatarComponent,
      event: event,
      cssClass: 'custom-popover',
      componentProps: { tenant: this.tenantLength }
    })

    await popOvers.present();
  }
  @Input() isSidebarVisible: boolean = false;  // Receives sidebar state from parent
  @Output() sidebarToggle = new EventEmitter<void>(); // Emits event to parent

  toggleSidebar(): void {
    this.sidebarToggle.emit(); // Notify parent component
  }
  isPopupVisible: boolean = false;
  activeTab: string = 'appointment';
  isVisibleUsage = false;
  isVisibleAudit = false;
  showTab(tab: string) {
    this.activeTab = tab; // Set active tab dynamically
    // navigate on basis of active tab appointment, usage, audit
    if (tab === 'usage-report') {
      this.isVisibleUsage = !this.isVisibleUsage;
      this.isVisibleAudit = false;
    } else if (tab === 'audit-report') {
      this.isVisibleAudit = !this.isVisibleAudit;
      this.isVisibleUsage = false;
    } else {
      this.isVisibleUsage = false;
      this.isVisibleAudit = false;
    }
    this.navigateToUrl(this.activeTab);
  }
 

  navigateToUrl(tab:any) {
    // close popup on navigation
       if(tab == 'appointment'){
        this.router.navigate(['/clinic-dashboard']);
      }else if(tab == 'usage-report'){
        this.router.navigate(['/app/usage-report']);
      }else if(tab == 'audit-report'){
        this.router.navigate(['/app/audit-report']);
      } 
    }

  public Status: Array<any> = [
    {
      name: 'All',
      id: 1
    },
    {
      name: 'In-Progress',
      id: 2
    },
    {
      name: 'Approved',
      id: 3
    },
    {
      name: 'Completed',
      id: 4
    } 
  ];
  statusAudit: Array<any> =    [
    {
      name: 'All',
      id: 1
    },
    {
      name: 'Hold',
      id: 2
    },
    {
      name: 'Ready',
      id: 3
    },
    {
      name: 'Sent',
      id: 4
    },
    {
      name: 'Failed',
      id: 5
    }
  ];

  public ApptType: Array<any> = [ 
  ];

  public Completion: Array<any> = [
    {
      name: 'Mobile',
      id: 1
    },
    {
      name: 'Tablet',
      id: 2
    },
    
  ];

  showDateCancel(){
    this.cancelBtn = true;
  }
  clearDate(){ 
    this.range = { start: null, end: null }; 
    this.cancelBtn = false;
  }
  
  listItems = ['Paris', 'Pune', 'Main Location', 'Mumbai', 'Nashik'];
  value: string[] = []; 
  tenantid: any;
  locationid: any;
  filterForm: FormGroup= this.fb.group({
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    status: [null, Validators.required],
    appointmentType: [null, Validators.required],
    completionMode: [null, Validators.required],
    location: [[], Validators.required]
  });
  auditReportForm: FormGroup = this.fb.group({
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    status: [null, Validators.required], 
  }) ;
    selectedLocations: any[] = [];
    private appointmentTypesLoaded: boolean = false;

  // add appointmenttype api from service
  fetchAppointmentTypes() { 
    let tenantid = this.commonService.getSessionStorage("tnt"); 
       let obj = { "tenantid": [this.tenantid] }
        if( tenantid != null  && !this.appointmentTypesLoaded)
        
        this.patientService.getAllQIntakeAppointments(obj).subscribe({
      next: (data: any) => {
        this.ApptType = data; 
      },
      error: (error: any) => {
        console.error('Error fetching appointment types', error);
      }
    }
        
      ); 
  
   
  }
   filterUsage!: any;
    applyFilter() {
      this.filterUsage = this.filterForm.value;
       this.dashboardService.usageReportBehaviour.next(this.filterUsage);
 
    } 
    applyFilterAudit() {
      this.filterUsage = this.auditReportForm.value;
      this.dashboardService.auditReportBehaviour.next(this.filterUsage);
    }
  
    range:any = { start: null, end: null };
    resetForm() {
      this.filterForm.reset();
      this.selectedLocations = [];
    }
    resetUsageForm(){
      this.filterForm.reset();
    }
    resetAuditReportForm() {
      this.auditReportForm.reset();
    }
}
