<div class="pos-rel">
<div *ngIf="this.loadingPanelVisible" class="k-i-loading loading-overlay"></div>

<div class="clinic_dashboard_provide_list_main">

  <div class="borderBottom20 p-10 providerSearch">
    <ion-searchbar #searchProvider placeholder="Search Providers" mode="q" (ionClear)="clear()"></ion-searchbar>
  </div>
  <div class="p-10 listMain" >
    <div class="provider_list_main" *ngIf="providerAll.length > 0">
      <div class="d-flex provider_list mb-5 align-items-center pointer" *ngIf="allprov"  [ngClass]="showActive? 'active' : ''" (click)="loadAllProvider($event)">
        <div class="width60"><ion-avatar class="patient_txt default-avatar"  slot="start">All</ion-avatar></div>
        <div class="w-100"><ion-text color="gray100" class="bodyM-txt-bold">All Providers</ion-text></div>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer" *ngFor="let prov of providerAll; let i=index;" [ngClass]="prov.isChecked? 'active' : ''" (click)="providerSelected($event,prov)">
        <div class="width60"><ion-avatar class="patient_txt default-avatar"  slot="start">
          {{prov.providerfirstname[0]}}{{prov.providerlastname[0]}}
        </ion-avatar>
        </div>
        <div class="w-100 providerName"><ion-text color="gray100" class="bodyM-txt-regular"><b>{{prov.providerfirstname}}</b>, {{prov.providerlastname}}</ion-text></div>
      </div>
      <!-- <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">LB</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Boseman</b>, Larry</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">
          <img src="https://i.pravatar.cc/150?img=9">
        </ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Calhoon</b>, Cindy</ion-text>
      </div>
      <div class="d-flex provider_list mb-5 align-items-center pointer">
        <ion-avatar class="patient_txt default-avatar"  slot="start">RM</ion-avatar>
        <ion-text color="gray100" class="bodyM-txt-regular"><b>Markle</b>, Rebecca</ion-text>
      </div> -->
    </div>
    <app-clinic-dashboard-search-noresult *ngIf="isNoResult"  [search]="'provider'" [searchTerm]="searchTerm"></app-clinic-dashboard-search-noresult>
  </div>
</div>
</div>