import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardLocationModel } from '../models/dashboardLocationModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { providerModel } from '../models/providerModel';
import { RequestPatient, patientModel } from '../models/patientModel';
import { patientDemographics } from '../models/patientDemograhicsModel';

@Injectable({
  providedIn: 'root'
})
export class DashboardStoreService {

  locationBehaviour: BehaviorSubject<DashboardLocationModel[]> = new BehaviorSubject<DashboardLocationModel[]>([]);
  locationObservable$: Observable<DashboardLocationModel[]> = this.locationBehaviour.asObservable();

  providerBehaviour: BehaviorSubject<providerModel[]> = new BehaviorSubject<providerModel[]>([]);
  providerObservable$: Observable<providerModel[]> = this.providerBehaviour.asObservable();


  patientsBehaviour: BehaviorSubject<patientModel[]> = new BehaviorSubject<patientModel[]>([]);
  patientObservable$: Observable<patientModel[]> = this.patientsBehaviour.asObservable();

  
  usageReportBehaviour: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  usageObservable$: Observable<any> = this.usageReportBehaviour.asObservable();

  auditReportBehaviour: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  auditObservable$: Observable<any> = this.auditReportBehaviour.asObservable();

  constructor(private http: HttpClient) {
  }

  getLocationByTenantId(tenantId: string): Observable<DashboardLocationModel[]> {
    return this.http.get<DashboardLocationModel[]>(environment.myqone_url + '/qappointments/location/getLocationBytenantId/' + tenantId);
  }

  getEnabledLocationByTenantId(tenantId: string): Observable<DashboardLocationModel[]> {
    return this.http.get<DashboardLocationModel[]>(environment.qintake_url + '/config/location/getAllEnabled/' + tenantId);
  }

  getAllProviderByLocation(tenantid: string): Observable<providerModel[]> {
    return this.http.get<providerModel[]>(environment.myqone_url + '/provider/intake/getAllProviderList/' + tenantid);
  }

  getAllPatientByLocation(tenantid: any) {

  }

  getAllPatientByProvider(providerData: any): Observable<RequestPatient[]> {
    return this.http.post<RequestPatient[]>(environment.qappoints_url + '/intake/clinic/patients', providerData);
  }

  getImage(url: any) {
    return this.http.get(url);
  }

  // Test

  // Existing
  getPatientDetails(id: string) {
    // return this.http.get<patientModel>(`https://qore-dev.myqone.com/patient/patient/getpatientbyid/${id}`)
    return this.http.get<patientModel>(environment.patient_url + `/patient/getpatientbyid/${id}`)
  }

  getPatientPharmacyDetails(sessionid: any, patientid: any) {
    return this.http.get(environment.qintake_url + `/pharmacy/pharmacycomparison/${sessionid}/${patientid}`)
  }

  // getDemographicsByPatient(id: string, tenantid: string) {
  //   //return this.http.get(`https://qore-dev.myqone.com/patient/patient/getinsurancesbypatientid/${tenantid}/${id}`)
  //    return this.http.get<patientModel>(environment.patient_url + `/patient/getinsurancesbypatientid/${tenantid}/${id}`)
  // }

  // // updated
  // getPatientDemographicsPast(id: string) {
  //  // return this.http.get<patientDemographics>(`https://qore-dev.myqone.com/patient/intake/queue/getPatientDemographics/${id}`)
  //   return this.http.get<patientModel>(environment.patient_url + `/intake/queue/getPatientDemographics/${id}`)
  // }

  // getDemographicsByPatientPast(id: string) {
  //   //return this.http.get(`https://qore-dev.myqone.com/patient/intake/queue/getPatientInsurancePlans/${id}`)
  //   return this.http.get<patientModel>(environment.patient_url + `/intake/queue/getPatientInsurancePlans/${id}`)
  // }

  getNewPatientDetails(id: string, sessionId: string) {
    return this.http.get(environment.qintake_url + `/queue/demographicscomparison/${id}/${sessionId}`)
  }

  getNewInsuranceDetails(id: string, sessionId: string) {
    return this.http.get(environment.qintake_url + `/queue/insurancecomparison/${id}/${sessionId}`)
  }

  publishDetails(data: any) {
    return this.http.post(environment.qintake_url + '/queue/publish', data, { responseType: 'text' })
  }

  getPCPDetails(pid:any,sid:any){
    return this.http.get(environment.qintake_url + `/pcp/comparison/${pid}/${sid}`)
  }

  getReferrerDetails(pid:any,sid:any){
    return this.http.get(environment.qintake_url + `/referral/comparison/${pid}/${sid}`)
  }

}
