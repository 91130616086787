<div class="audit-report-wrapper l-fill--scroll-y">
  <div class="audit_report_header" *ngIf="isTablet || !pageOn">
    <app-clinic-dashboard-header [isSidebarVisible]="isSidebarVisible" (sidebarToggle)="onSidebarToggle()"
      [title]="title"></app-clinic-dashboard-header>
  </div>
  <div class="d-flex">
    <div class="l-fill" [ngClass]="{ 'sidebar-visible': isSidebarVisible, 'sidebar-hidden': !isSidebarVisible }">

      <app-clinic-dashboard-sidebar [activeTab]="activeTab"
        (tabChange)="setActiveTab($event)"></app-clinic-dashboard-sidebar>
    </div>
    <div class="l-fill w-100 overflow-y-hidden">
      <div class="audit-report-main">


        <!-- <div [formGroup]="messageReportFilterForm"
      class="patient_report_filter d-flex my-10 mt-0 align-items-center justify-content-sb">
      <ion-text class="bodyL-txt-bold text-blue-color showOnPreRegister">Audit Report</ion-text>

      <div class="d-flex align-items-center filter-audit">
        <div class="audit-report-filters d-flex align-items-center mr-25">
          <div class="form-filter d-flex">
            <div class="d-flex mr-20 ion-align-items-center status-form">
              <div class="wrapper mb-10 mobile-width">
                <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text color="gray80">Status <span class="ml-3"
                      style="color: red;">*</span></ion-text></label><br>
                <kendo-combobox placeholder="Status" class="body2-txt statusList" formControlName="status"
                  [data]="messageStatusData" [valuePrimitive]="true" [filterable]="true" textField="value"
                  [valueField]="'id'" #combo (valueChange)="statusValueChange($event)"></kendo-combobox>
              </div>
            </div>
            <div *ngIf="isDateRangeVisible" class="wrapper mb-10 mobilefullwidth">
              <label class="form-label ">
                <ion-text class="bodyS-txt uppercase mr-10" color="gray80">Date Range
                  <span class="ml-3" style="color: red;">*</span></ion-text>
              </label>
              <kendo-daterange class="d-flex mr-20 ion-align-items-center" #daterange>
                <kendo-dateinput [incompleteDateValidation]="true" placeholder="From" color="gray40"
                  class="ion-color-gray40 body1-txt" formControlName="fromDate" kendoDateRangeStartInput
                  [(value)]="range.start" (valueChange)="fromDateValueChange($event)"></kendo-dateinput>
                <ion-text class="mx-10" color="gray100">
                  &#8212;
                </ion-text>
                <kendo-dateinput [incompleteDateValidation]="true" placeholder="To" kendoDateRangeEndInput
                  formControlName="toDate" [(value)]="range.end" color="gray40" class="ion-color-gray40 body1-txt"
                  (valueChange)="toDateValueChange($event)"></kendo-dateinput>
              </kendo-daterange>
              <label *ngIf="isDateRangeError" class="form-label ">
                <span>test </span>
                <ion-text class="bodyXS-txt mt-10 d-block" style="color:#f31700">Please select valid date range.
                </ion-text>
              </label>
            </div>
          </div>
          

          <div class="d-flex pat_reg">
            <ion-button fill="solid" expand="block" color="ion-color-white"
              class="filled-icon-btn capitalize info-btn mr-10"
              [disabled]="!isDateRangeValid || !messageReportFilterForm.valid || isDateRangeError"
              (click)="applyFilter()">
              <ion-text>Apply</ion-text>
            </ion-button>
            <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn"
              (click)="clearFilter()" [disabled]="!isResetDisable">
              <ion-text>Reset</ion-text>
            </ion-button>
          </div>
        </div>
        <div class="export-btn">
          <div>
            <img src="../../../../assets/img/ExportExcel_30px.png" [ngClass]="isPage ? 'pointer' : '' "
              title="Export Report" (click)="exportUserList()">
          </div>
        </div>
      </div>
     
    </div> -->


        <div [formGroup]="messageReportFilterForm" class="column-new audit-report-filter px-20 py-20">
          <div class="row-new align-items-center justify-content-sb">
            <div class="patient_report_filter">

              <!-- <div class="back-icon" (click)="navigateToDashboard()">
              
                <ion-icon class="font-16 link-color pr-10 pointer" src="../../../../assets/svg/left-back-arrow.svg"></ion-icon>
              
              
            </div> -->
              <ion-text class="h3 text-blue-color showOnPreRegister">Audit Report</ion-text>


            </div>


            <!-- <div class="col-8-xl col-9-lg col-12-md col-12-sm">
          <div class="row-new align-items-center justify-content-end">
            <div class="mb-10 col-3-xl col-3-lg col-7-md ml-16-nw col-6-sm col-12-xs pr-20">
              <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text color="gray80">Status <span class="ml-3"
                    style="color: red;">*</span></ion-text></label><br>
              <kendo-combobox placeholder="Status" class="body2-txt statusList" formControlName="status"
                [data]="messageStatusData" [valuePrimitive]="true" [filterable]="true" textField="value"
                [valueField]="'id'" #combo (valueChange)="statusValueChange($event)"></kendo-combobox>
            </div>

            <div  class="mb-10 col-4-xl col-5-lg col-5-md ml-16-nw col-6-sm col-12-xs">
              <label class="form-label ">
                <ion-text class="bodyS-txt uppercase mr-10" color="gray80">Date Range
                  <span class="ml-3" style="color: red;">*</span></ion-text>
              </label>
              <kendo-daterange class="d-flex mr-20 ion-align-items-center" #daterange>
                <kendo-dateinput [incompleteDateValidation]="true" placeholder="From" color="gray40"
                  class="ion-color-gray40 body1-txt" formControlName="fromDate" kendoDateRangeStartInput
                  [(value)]="range.start" (valueChange)="fromDateValueChange($event)"></kendo-dateinput>
                <ion-text class="mx-10" color="gray100">
                  &#8212;
                </ion-text>
                <kendo-dateinput [incompleteDateValidation]="true" placeholder="To" kendoDateRangeEndInput
                  formControlName="toDate" [(value)]="range.end" color="gray40" class="ion-color-gray40 body1-txt"
                  (valueChange)="toDateValueChange($event)"></kendo-dateinput>
              </kendo-daterange>
              <label *ngIf="isDateRangeError" class="form-label ">
                
                <ion-text class="bodyXS-txt mt-10 d-block" style="color:#f31700">Please select valid date range.
                </ion-text>
              </label>
            </div>

            <div class="col-4-xl col-3-lg col-5-md col-5-sm col-6-xs pl-10 pl-0-nw">
              <div class="row-new align-items-center">
                <div class="col-6-xl col-6-lg col-6-md col-6-sm col-6-xs">
                  <ion-button fill="solid" expand="block" color="ion-color-white"
                    class="filled-icon-btn capitalize info-btn mr-10 w-100"
                    [disabled]="!isDateRangeValid || !messageReportFilterForm.valid || isDateRangeError"
                    (click)="applyFilter()">
                    <ion-text>Apply</ion-text>
                  </ion-button>
                </div>

                <div class="col-6-xl col-6-lg col-6-md col-6-sm col-6-xs">
                  <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn w-100 ml-10"
                    (click)="clearFilter()" [disabled]="!isResetDisable">
                    <ion-text>Reset</ion-text>
                  </ion-button>
                </div>
              </div>
            </div>

            <div class="export-btn col-1-xl col-1-lg col-7-md col-7-sm col-6-xs">
              <div>
                <img src="../../../../assets/img/ExportExcel_30px.png" [ngClass]="isPage ? 'pointer' : '' "
                  title="Export Report" (click)="exportUserList()">
              </div>
            </div>

          </div>
        </div> -->
            <div class="export-btn">
              <div>
                <img src="../../../../assets/img/ExportExcel_30px.png" [ngClass]="isPage ? 'pointer' : '' "
                  title="Export Report" (click)="exportUserList()">
              </div>
            </div>

          </div>
        </div>
        <ng-template #template let-anchor>
          <span style="padding: 5px !important; border-radius: 5px !important;">{{
            anchor.nativeElement.innerText }}</span>
        </ng-template>

        <div kendoTooltip position="bottom" showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
          (mouseover)="showTooltip($event)" tooltipClass="grid-tooltip">

          <div class="bor-top grid-main">
            <kendo-grid class="report_grid w-100 showScrollOnHover" [data]="gridMessageDetails" [columnMenu]="true"
              [loading]="loadingPanelVisible" [pageSize]="pageSize" [skip]="skip" [pageable]="gridMessageDetails?.data?.length > 0 ? {
            buttonCount: buttonCount,
            info: info,
            type: type,
            pageSizes: pageSizes,
            previousNext: previousNext,
            position: position
          } : false" (pageChange)="pageChange($event)">

              <kendo-grid-column class="grid-text-ellipse" field="recipient" title="Patient Name" [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem?.lastname | uppercase}}, {{dataItem?.firstname | titlecase}}
                  <span *ngIf="!(dataItem?.preferredname == null || dataItem?.preferredname == '')">
                    "{{dataItem?.preferredname | titlecase}}"
                  </span>
                  <span *ngIf="!(dataItem?.middlename == null || dataItem?.middlename == '')">
                    {{dataItem?.middlename| initials}}.
                  </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="patientcode" title="Patient Code" [width]="120"></kendo-grid-column>

              <kendo-grid-column class="grid-text-ellipse" title="Appointment Date" format="MM/dd/yyyy" [width]="190">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ion-text>{{dataItem?.apttdate}}</ion-text>
                </ng-template>
              </kendo-grid-column>


              <kendo-grid-column field="status" title="Status" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ion-text>{{dataItem?.status | titlecase}}</ion-text>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column class="grid-text-ellipse" field="reason" title="Reason" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ion-text>{{dataItem?.reason | titlecase}}</ion-text>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column class="grid-text-ellipse" field="textmessage" title="Message Content" [width]="200"
                [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ion-text>{{dataItem?.textmessage}} </ion-text>
                  <!-- <ion-text>No Data</ion-text> -->
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column class="grid-text-ellipse" title="Message sent on" format="MM/dd/yyyy" [width]="180">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ion-text>{{dataItem?.msgSentDate}} {{dataItem?.msgSentTime | uppercase}}</ion-text>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-command-column *ngIf="isActionVisible" title="Action" class="btn" [columnMenu]="false"
                [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="d-flex align-items-center pointer statusBtn2"
                    (click)="updateMessageStatus('Hold',dataItem?.sessionid)"
                    *ngIf="dataItem?.status == 'Ready'&& dataItem?.isHoldReady">
                    <ion-icon class="h4 mr-5" src="../../../../assets/svg/Hold_white 1.svg"></ion-icon>
                    <ion-text class="bodyM-txt" color="white">Hold</ion-text>
                  </div>
                  <div class="d-flex align-items-center pointer statusBtn"
                    (click)="updateMessageStatus('Ready',dataItem?.sessionid)"
                    *ngIf="dataItem?.status == 'Hold' && dataItem?.isHoldReady && dataItem?.reason != 'mobile not available'">
                    <ion-icon class="h4 mr-5" src="../../../../assets/svg/Ready_white 1.svg" color="white"></ion-icon>
                    <ion-text class="bodyM-txt" color="white">Ready</ion-text>
                  </div>
                  <!-- <ion-icon class="h3" *ngIf="dataItem.status == 'Hold'"  src="../../../../assets/svg/Ready_icon_24px.svg" color=""></ion-icon> -->
                  <!-- <img *ngIf="dataItem.status == 'Hold'"  src="../../../../assets/svg/Ready_icon_png.png" class="statusImg pointer"/> -->
                  <ion-text *ngIf="dataItem?.status == 'sent'" class=""></ion-text>
                  <ion-text *ngIf="dataItem?.status == 'failed'" class=""></ion-text>
                </ng-template>
              </kendo-grid-command-column>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>