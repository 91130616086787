<div class="usage-report-wrapper l-fill--scroll-y">


  <div class="usage_report_header" *ngIf="isTablet && !pageOn">
    <app-clinic-dashboard-header></app-clinic-dashboard-header>
  </div>
  <div class="usage_report_header" *ngIf="!isTablet && !pageOn">
       <app-clinic-dashboard-header [isSidebarVisible]="isSidebarVisible" (sidebarToggle)="onSidebarToggle()"
        [title]="title"></app-clinic-dashboard-header>
   </div> 
  <div class="d-flex">
    <div class="l-fill" [ngClass]="{ 'sidebar-visible': isSidebarVisible, 'sidebar-hidden': !isSidebarVisible }">

      <app-clinic-dashboard-sidebar [activeTab]="activeTab"
        (tabChange)="setActiveTab($event)"></app-clinic-dashboard-sidebar>
    </div>
    <div class="l-fill w-100 overflow-y-hidden">
      <div class="usage-report-main">
        <div [formGroup]="usageReportForm" class="column-new usage-report-filters px-20 py-20">
          <div class="row-new align-items-center justify-content-sb ">
            <div class="patient_report_filter"> 
              <ion-text class="h3 text-blue-color showOnPreRegister">Usage Report</ion-text>
            </div> 
            <div class="export-btn">

              <img src="../../../../assets/img/ExportExcel_30px.png" [ngClass]="isPage ? 'pointer' : '' "
                (click)="exportUserList()" title="Export Report">

            </div>
          </div>

        </div>



        <div class="bor-top grid-main">
          <kendo-grid class="report_grid w-100 showScrollOnHover" [columnMenu]="true" [data]="gridData"
            [loading]="loadingPanelVisible" [pageSize]="pageSize" [skip]="skip" [pageable]="isPage">

            <kendo-grid-column field="recipient" title="Start Date" format="MM/dd/yyyy" [width]="200">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ion-text>{{dataItem.startDate}}</ion-text>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="patientcode" title="End Date" format="MM/dd/yyyy" [width]="250">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ion-text>{{dataItem.endDate}}</ion-text>
              </ng-template>
            </kendo-grid-column>


            <kendo-grid-column field="status" title="Mobile" [width]="250">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ion-text>{{dataItem.mobileCount}}</ion-text>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column class="text-ellipse" field="textmessage" title="Tablet" [width]="350"
              [filterable]="false">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ion-text>{{dataItem.tabletCount}}</ion-text>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>