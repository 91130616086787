import { Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { PatientService } from 'src/app/core/services/patient.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { CommonService } from 'src/app/core/services/common.service';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { ToasterService } from 'src/app/services/toaster.service';
@Component({
  selector: 'otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.scss'],
})
export class OtpLoginComponent implements OnInit {
  isDesktop: boolean;
  ismobile: boolean;
  breakpoint: any = window.matchMedia('(min-width: 768px)');
  mobileview: any = window.matchMedia('(max-width: 768px)');
  mask: string = '(000)-000-0000';
  iserrorShow: boolean = false;
  CountryCode: string = '+1';
  phoneNumber: any = '';
  recaptchaVerifier!: firebase.auth.RecaptchaVerifier;
  PhoneForm!: FormGroup;
  getnumber: any;
  sid: any;
  patientId: any;
  tenantId: any;
  refid: any;
  getInfo: any;
  isPatient: boolean = true;
  errormsg: any;
  isClicked: boolean = false;
  loadingPanelVisible: boolean = false;
  @ViewChild('captchaContainer') captchaContainer!: ElementRef;

  constructor(private router: Router, private fb: FormBuilder,
    private fireService: FirebaseService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private auditService: AuditService,
    private toaster: ToasterService) {
    document.addEventListener('DOMContentLoaded', () => {

    });
    this.isDesktop = !!this.breakpoint.matches;
    this.ismobile = !!this.mobileview.matches;
    this.isDesktop = !!this.breakpoint.matches;
    this.ismobile = !!this.mobileview.matches;
    this.PhoneForm = this.fb.group({
      phone: ['', Validators.required],
    });
  }

  ngOnInit() {

    // this.sid = this.route.snapshot.paramMap.get('id');
    // if (this.sid) {
    //   sessionStorage.setItem('sid', this.sid); //Set RefID in Session
    // }
    // this.route.queryParams.subscribe(params => {
    //   this.refid = params['refid'];
    // });
    this.setNewUser();
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response: string) => {
        },
        'expired-callback': () => {
        },

      }
    );
    this.refid = this.route.snapshot.paramMap.get('id')

    this.auditService.writeOparationalLog('Access Mobile Link#Access#' + "#" + this.refid);


  }

  async setNewUser() {
    const auth = getAuth();
    await signInAnonymously(auth).then((userCredential) => {
      let user = userCredential.user;
      const accessToken = user["accessToken"];
      let obj = {
        userid: user.uid,
        token: accessToken
      };
      this.commonService.setSessionStorage('tid', JSON.stringify(this.tenantId));
      this.commonService.setSessionStorage('tk', JSON.stringify(obj.token));
    }).catch((error) => {
      console.error(error);
      this.toaster.showError('Error signing in anonymously');
    });

  }

  getPatientInfo() {
    this.isClicked = true;
    this.loadingPanelVisible = true;
    this.errormsg = '';
    this.auditService.writeOparationalLog('send code button#click#' + "#" + this.refid);


    try {
      let obj = {
        "referenceid": this.refid,
        "mobileno": this.phoneNumber
      }
      this.patientService.getPatientInfo(obj).subscribe((res) => {
        if (res) {
          this.getInfo = res
          this.errormsg = ''
          this.commonService.setSessionStorage('pid', JSON.stringify(this.getInfo.patientid));
          this.commonService.setSessionStorage('tid', JSON.stringify(this.getInfo.tenantid));
          this.commonService.setSessionStorage('sid', JSON.stringify(this.getInfo.sessionid));
          // this.commonService.setSessionStorage('tk', JSON.stringify(this.getInfo.token));
          if (this.getInfo.token) {
            this.commonService.setSessionStorage('tk', JSON.stringify(this.getInfo.token));
          }

          this.commonService.setSessionStorage('prn', JSON.stringify(this.phoneNumber))
          this.sendCode();
          this.commonService.setSessionStorage('isPt', JSON.stringify(this.isPatient))

        }
      },
        (error) => {
          if (error) {
            this.isClicked = false;
            this.errormsg = 'Mobile number is not valid.';
            this.loadingPanelVisible = false;
            this.auditService.writeOparationalLog('mobile verification failed status #failed#' + "#" + this.refid);

            // console.error('Error  - Not Found:', this.errormsg);
          }
          // else {
          //   this.errormsg = error.message || 'An error occurred';
          //   // console.error('Error in response:', this.errormsg);
          // }
        }
      );
    } catch (error) {
      this.errormsg = error;
      this.isClicked = false;
      this.loadingPanelVisible = false;
      console.error('Error:', this.errormsg);
    }
  }

  //function for sending otp on mobile number
  sendCode() {
    this.fireService.phoneNumber = this.CountryCode + this.phoneNumber;
    if (this.phoneNumber && this.CountryCode) {
      this.fireService
        .signwithNumber(
          this.recaptchaVerifier,
          this.CountryCode + this.phoneNumber
        )
        .then((success) => {
          this.errormsg = ''
          this.router.navigateByUrl('/otp-verify').then(() => {
            this.auditService.writeOparationalLog('mobile verification success status#success#' + this.getInfo.patientid + "#" + this.getInfo.sessionid);
            this.isClicked = false;
            this.loadingPanelVisible = false;
          })
        }, ((error) => {
          this.isClicked = false;
          this.loadingPanelVisible = false;
          switch (error.code) {
            case "user-not-found":
              this.errormsg = "No user found with this email.";
              break;
            case "ERROR_USER_DISABLED":
            case "user-disabled":
              this.errormsg = "User disabled.";
              break;
            case "ERROR_TOO_MANY_REQUESTS":
            case "operation-not-allowed":
            case "auth/too-many-requests":
              this.errormsg = "Too many requests to log into this account. Please try after some time";
              break;
            case "ERROR_OPERATION_NOT_ALLOWED":
            case "operation-not-allowed":
              this.errormsg = "Server error, please try again later.";
              break;

            case "INVALID_APP_CREDENTIAL":
              this.errormsg = "Invalid Recaptcha, Please try again.";
              break;
            default:
              this.errormsg = "Login failed. Please try again.";
              break;
          }
        }));
    }
  }

}
