import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import firebase from 'firebase/compat/app';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private countdownSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  confirmationResult!: any;
  _phoneNo: string = '';
  public get phoneNumber() {
    return this._phoneNo;
  }

  public set phoneNumber(value: string) {
    if (value != '') this._phoneNo = value;
  }

  constructor(private fireAuth: AngularFireAuth, private http: HttpClient) { }

  public signwithNumber(recaptchaVerifier: any, phoneNumber: any) {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth
        .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          let user = firebase.auth().currentUser;
          this.confirmationResult = confirmationResult;
          resolve(user);
          this.startTimer();
        })
        .catch((error) => {
          reject('SMS not sent');
        });
    });
  }

  public async enterVerificationCode(code: any) {
    return new Promise<any>((resolve, reject) => {
      this.confirmationResult
        .confirm(code)
        .then(async (result: any) => {
          const user = result.user;
          // sessionStorage.setItem('uid', user.uid);
          resolve(user);
        })
        .catch((error: Error) => {
          reject(error.message);
        });
    });
  }

  get countdown$() {
    return this.countdownSubject.asObservable();
  }

  startTimer() {
    this.countdownSubject.next(60);
    const interval = setInterval(() => {
      const currentValue = this.countdownSubject.getValue();
      if (currentValue === 1) {
        clearInterval(interval);
      }
      this.countdownSubject.next(currentValue - 1);
    }, 1000);
  }

  getCountdown(): Observable<number> {
    return this.countdownSubject.asObservable();
  }
}
