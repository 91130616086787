import { EventEmitter, Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, catchError, Observable, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';
import { messageModel } from '../models/messageModel';



@Injectable({
  providedIn: 'root'
})


export class PatientService {
  token: any;
  user: any;
  messageFilter: messageModel = new messageModel();
  constructor(private http: HttpClient,
    private commonService: CommonService) { }

  private getToken(): string {
    this.token = this.commonService.getSessionStorage('PI');
    const parsedToken = this.token;
    return parsedToken.token;
  }

  forms: { formid: string; isFilled: boolean }[] = [];
  formFilledEvent = new EventEmitter<string>();
  preferredPharmacy = new BehaviorSubject<any>([])
  preferredPCP = new BehaviorSubject<any>([])
  referralProvider = new BehaviorSubject<any>([])
  cleanZipSubject = new BehaviorSubject<boolean>(false);

  public filledform = new Subject<any>();
  filledform$ = this.filledform.asObservable();

  public closepopup = new Subject<boolean>();
  closepopup$ = this.closepopup.asObservable();

  // public singleform = new Subject<boolean>();
  // singleform$ = this.singleform.asObservable();

  public singleform = new BehaviorSubject<any>({ value1: null, value2: null, value3: null });
  singleform$ = this.singleform.asObservable();

  public doneform = new BehaviorSubject<any>(null);
  doneform$ = this.singleform.asObservable();


  public completeSteps = new BehaviorSubject<any>(null);
  completeSteps$ = this.singleform.asObservable();


  public completeMobileSteps = new BehaviorSubject<any>(null);
  completeMobileSteps$ = this.singleform.asObservable();

  public doneMobileform = new BehaviorSubject<any>(null);
  doneMobileform$ = this.singleform.asObservable();

  public previewForm = new BehaviorSubject<any>(null);
  previewForm$ = this.previewForm.asObservable();

  public medicalCompleteForm = new BehaviorSubject<any>(false);
  medicalCompleteForm$ = this.medicalCompleteForm.asObservable();

  public storeDemographics = new BehaviorSubject<any>(null);
  storeDemographics$ = this.storeDemographics.asObservable();

  public storeInsurance = new BehaviorSubject<any>(null);
  storeInsurance$ = this.storeInsurance.asObservable();


  updateFormStatus(formId: string, status: boolean) {
    const form = this.forms.find((f) => f.formid === formId);
    if (form) {
      form.isFilled = true;
      // Emit the custom event when a form is filled
      this.formFilledEvent.emit(formId);
    }

  }

  // initializeFormStatus(formId: string) {
  //   if (!this.formStatus[formId]) {
  //     this.formStatus[formId] = new BehaviorSubject<boolean>(false);
  //   }
  // }


  //get patient demographic details
  patientDemographicDetails(patientId: any): Observable<any> {
    return this.http.get<any>(environment.patient_url + "/patient/getpatientbyid" + "/" + patientId);
  }
  //to get the patient id's(tid,aid,pid)


  //to get the patient appointments details
  getAppointmentDetails(RefId: any) {
    return this.http.get<any>(environment.qappoints_url + "/intake/getAppointmentDetails/" + RefId);
  }

  //to get patient insurance detail
  getPatientDetails(tenantId: any, patientId: any): Observable<any> {
    return this.http.get<any>(environment.patient_url + "/patient/getinsurancesbypatientid/" + tenantId + "/" + patientId);
  }

  getInsuranceCompanyDetails(insuranceplanid: any, tenantid: any): Observable<any> {
    return this.http.get<any>(environment.myqone_url + "/insurance/insuranceplan/getInsuranceCompany/" + insuranceplanid + "/" + tenantid);
  }

  //save patient demographic into queue
  // savePatientIntakeQue(data: any) {
  //   return this.http.post(environment.patient_url + "/intake/queue/save", data);
  // }
  //save patient demographic into queue


  // //get QORE insurance list 
  // getInsuranceList(tenantId: any, searchtext: any) {
  //   return this.http.get<any>(environment.myqone_url + "/insurance/insuranceplan/getTenantInsurance" + "?" + "tenantid" + "=" + tenantId);
  // }

  getInsuranceList(tenantId: any) {
    return this.http.get<any>(environment.myqone_url + "/insurance/insuranceplan/getinsurancplan/" + tenantId);
  }

  //get patient queue detail
  // getpatientInfo(sessionId: any) {
  //   return this.http.get<any>(environment.patient_url + "/intake/queue/" + sessionId);
  // }

  //get insurance card
  getInsuranceCardDetails(patientId: any) {
    return this.http.get<any>(environment.patient_url + "/patient/getPatientCards/" + patientId);
  }

  //update patient demographic and insurancein queue
  // updatePatientIntakeQue(data: any) {
  //   return this.http.put(environment.patient_url + "/intake/queue/update", data);
  // }

  // save session
  saveSession(saveSessionObject: any): Observable<any> {
    return this.http.post<any>(environment.qintake_url + '/session/create', saveSessionObject);
  }

  //get session information
  getSessionInfoByReferenceId(sessionId: any) {
    return this.http.get<any>(environment.qintake_url + '/session/getSessionInfoBySessionid/' + sessionId);
  }

  //update session
  updateSession(data: any) {
    return this.http.put<any>(environment.qintake_url + '/session/update', data);
  }

  //zip drodown
  getZIP(text: string): Observable<any> {
    return this.http.get(environment.myqone_url + '/gallery/zip?pagenumber=0&searchtext=' + text + '&size=100&sortby=st');
  }

  getSessionDetails(tenantId: string) {
    return this.http.get(environment.qintake_url + `/sections/setting/${tenantId}`);
  }

  // get all form of one category
  getFormbyFtid() {
    return this.http.get<any[]>(environment.myqone_url + `/forms/formtype/getform/7b1d4cac-e4ff-489f-a9a3-32cc10cff944`);
  }
  saveForms(data: any) {
    return this.http.post<any[]>(environment.myqone_url + `/forms/saveuserform`, data);
  }

  getFilledForms(refid: any, formid: any, patientid: any) {
    return this.http.get<any[]>(environment.myqone_url + `/forms/intake/clinic/getform/${refid}/${formid}/${patientid}`);
  }

  getSavedFormData(refid: any, patientid: any) {
    return this.http.get<any[]>(environment.myqone_url + `/forms/intake/clinic/getdistinctformid/${refid}/${patientid}`);
  }

  getFormbyType(tenantid: any, appointmentId: string) {
    return this.http.get<any[]>(environment.myqone_url + `/forms/v2/getformsbyappttype?tenantid=${tenantid}&appointmenttypeid=${appointmentId}`);
  }

  getFormListBySessionId(sessionId: any) {
    return this.http.get<any[]>(environment.myqone_url + `/forms/getuserformslist/${sessionId}`);
  }

  getNewForm(formid: any) {
    return this.http.get<any[]>(environment.myqone_url + `/forms/previewformsection/${formid}`);
  }

  getUserForm(patientid: any, formid: any, sessionid: any) {
    return this.http.get<any[]>(environment.myqone_url + `/forms/getuserform/${patientid}/${formid}/${sessionid}`);
  }

  updateSavedForm(userid: any, data: any) {
    return this.http.put<any>(environment.myqone_url + `/forms/updateuserform/${userid}`, data);
  }

  getPortalFormById(payload: any) {
    // return this.http.get('http://localhost:80/forms/portal/get?id=2432')
    return this.http.post(environment.myqone_url + `/forms/portalform/details`,payload)
  }

  
  savePortalFormById(payload: any) {
    // return this.http.put(`http://localhost:80/forms/portal/update/${payload.appointmentdetailid}`,payload)
    return this.http.post(environment.myqone_url + `/forms/portalform/save`,payload) // update
  }


  updatePortalFormById(payload: any) {
    // return this.http.put(`http://localhost:80/forms/portal/update/${payload.appointmentdetailid}`,payload)
    return this.http.put(environment.myqone_url + `/forms/portalform/modify`,payload) // update
  }

  checkSavedforms(sessionid: any) {
    return this.http.get<any[]>(environment.myqone_url + `/forms/getUserFormStatus/${sessionid}`);
    // getpatientInfo(sessionId: any) {
    //   return this.http.get<any>(environment.patient_url + "/intake/queue/" + sessionId);
  }

  intialFormByStatus(sessionid: any) {
    return this.http.get<any[]>(environment.myqone_url + `/forms/getuserbystatus/${sessionid}`);
    // getpatientInfo(sessionId: any) {
    //   return this.http.get<any>(environment.patient_url + "/intake/queue/" + sessionId);
  }
  getDemographicDetail(sessionId: any) {
    return this.http.get<any>(environment.qintake_url + "/queue/getPatientDemographics/" + sessionId);
  }

  getPatientInsurances(sessionId: any) {
    return this.http.get<any>(environment.qintake_url + "/queue/getPatientInsurancePlans/" + sessionId);
  }

  deletePatientInsurance(sessionId: any, insuranceplanid: any, id: any) {
    return this.http.delete<any>(environment.qintake_url + `/queue/insurance/delete/${sessionId}/${insuranceplanid}/${id}`);
  }

  getPatientInsurance(sessionId: any, insuranceplanid: any) {
    return this.http.get<any>(environment.qintake_url + `/queue/getPatientInsurancePlan/${sessionId}/${insuranceplanid}`);
  }


  getInsuranceCompany(tenantId: any, insurancecompanyid: any) {
    return this.http.get(environment.myqone_url + `/insurance/insuranceplan/getInsurancePlanList/${tenantId}/${insurancecompanyid}`)
  }


  updateDemographicDetail(data: any) {
    return this.http.put(environment.qintake_url + "/queue/demographics/update", data);
  }

  updateInsuranceDetail(data: any) {
    return this.http.put(environment.qintake_url + "/queue/insurance/update", data);
  }

  savePatientDemoIntakeQue(data: any) {
    return this.http.post(environment.qintake_url + "/queue/demographics/save", data);
  }

  //save patient demographic into queue
  savePatientInsuIntakeQue(data: any) {
    return this.http.post(environment.qintake_url + "/queue/insurance/save", data);
  }

  //filter patient by status
  filterPatientByStatus(data: any) {
    return this.http.post(environment.qintake_url + "/session/getStatus", data);
  }

  //relation list
  relationList() {
    return this.http.get(environment.myqone_url + "/insurance/patientrelationship/getAll")
  }
  //get emr insurance list
  getEmrInsuranceList(tenantId: any, emrId: any) {
    return this.http.get(environment.myqone_url + "/insurance/insuranceplan/getinsuranceplanByemrid/" + tenantId + "/" + emrId)
  }

  // SavePreviewPDF(file: File, payloadData: any) {
  //   const formData: FormData = new FormData();
  //   formData.append('file', file, 'file.pdf');
  //   formData.append('patientid', payloadData.patientid);
  //   formData.append('tenantid', payloadData.tenantid);
  //   return this.http.post('https://qdrive-dev.myqone.com/dms/intake/save/pdf', formData);

  // }

  SavePreviewPDF(file: File, payloadData: any) {
    const formData: FormData = new FormData();
    formData.append('file', file, 'file.pdf');
    formData.append('patientid', payloadData.patientid);
    formData.append('tenantid', payloadData.tenantid);
    return this.http.post(environment.myqone_url + '/qdrive/intake/pdf/save', formData);

  }

  getAllCompanyList(tenantId: any) {
    return this.http.get(environment.myqone_url + `/insurance/insurancecompany/getInsuranceCompanyListByTenantid/${tenantId}`)
  }

  saveFormToDMS(data: any) {
    return this.http.post(environment.myqone_url + "/forms/intake/getpdfdetails", data);
  }

  getPdfBystorage(tenantid: any, storagefilename: any) {
    // let header = { "Accept": "application/json" }
    return this.http.get(environment.myqone_url + `/qdrive/intake/document/doccontent/
    ${tenantid}?storagefilename=${storagefilename}`, { responseType: 'blob' })
  }

  postImportDocument(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data" // 👈
      })
    };
    return this.http.post(environment.dms_url + `/legacy/document/importdocument`, payload)
  }

  getDmsDocumentDetailByDocId(docid: string, tenantid: string, patientid: string, formid: string, sessionid: string) {
    return this.http.get(environment.myqone_url +
      `/forms/intake/getdmsdocdetails?edocumentid=${docid}&tenantid=${tenantid}&patientid=${patientid}&formid=${formid}&sessionid=${sessionid}`
    )
  }

  getDmsDocumentDetail(docid: string, tenantid: string, patientid: string) {
    return this.http.get(environment.myqone_url +
      `/forms/intake/documentdetails?edocumentid=${docid}&tenantid=${tenantid}&patientid=${patientid}`
    )
  }
 
  skipFormByAPI(formid: string, sessionid: string, patientid: string,payload: object) {
    return this.http.post(environment.myqone_url +
      `/forms/skipform?formid=${formid}&sessionid=${sessionid}&patientid=${patientid}`,payload
    )
  }

  getStorageFile(tenantId: any, storagefilename: any) {
    let params = new HttpParams();
    params = params.append('storagefilename', storagefilename);
    return this.http.get(environment.dms_url + `/intake/document/doccontent/${tenantId}`, { params })
  }

  getRefById(id: any) {
    return this.http.get<any>(environment.qappoints_url + "/intake/getAccessByReferenceid" + "/" + id)
  }

  getPatientInfo(obj: any) {
    // let token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDgwNzQ1NzgsImV4cCI6MTcxMDY2NjU3OCwiaXNzIjoiY2xvdWQtZW5kcG9pbnRzQHFwYXRod2F5cy1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL3FvcmUtZGV2Lm15cW9uZS5jb20iLCJzdWIiOiJjbG91ZC1lbmRwb2ludHNAcXBhdGh3YXlzLWRldi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImVtYWlsIjoiZ2F5YXRyaS5oYXJpc2hjaGFuZHJlQHRyaWFycWhlYWx0aC5jb20iLCJ1c2VyaWQiOiIxN2I2YTI3OS1kMzc2LTExZWQtYmVmMi00YjE3MjNjMTExMGIiLCJmaXJzdG5hbWUiOiJnYXlhdHJpIiwibGFzdG5hbWUiOiJoYXJpc2hjaGFuZHJlIn0.Jy75z1w2WLjF-lIOUUUt8or2GE8gWbh3_WT0ILKO0pSXsNU_5h8PTt-6shdoyTmyhcLGDn-r-1sweBmKn_Ptq7nlS1SaXaN_9XCO7lxC14jkzOWulGpmu_eCZyRQ3Zm1pxrLnwfhHzFNnWCqdTvpXFN-KLOYku8TUA1qfJYQySS2l5hmrxHkacUBIaMl5zj39CzmVu1quga7vdeGud5qoxt5q3iEveitjJ7QX1qmrv9alJT2CcCjN-cpHpac_vzUL5PO-rx-VU56LZyNlZ4GJYuz-IGkfffWKGbWw9C34GixJIm3P74Vyx5RQCuKE1Jgq1tJaLR2hGF92cCbJHuA_A"
    return this.http.post(environment.auth_url + "/intake/getAccessByReferenceid", obj)
    // {
    //   headers: new HttpHeaders().set('Authorization', 'Bearer ' + token)
    // });
  }
  // getMobile(pid: any, tid: any) {
  //   //let token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDgwNzQ1NzgsImV4cCI6MTcxMDY2NjU3OCwiaXNzIjoiY2xvdWQtZW5kcG9pbnRzQHFwYXRod2F5cy1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL3FvcmUtZGV2Lm15cW9uZS5jb20iLCJzdWIiOiJjbG91ZC1lbmRwb2ludHNAcXBhdGh3YXlzLWRldi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImVtYWlsIjoiZ2F5YXRyaS5oYXJpc2hjaGFuZHJlQHRyaWFycWhlYWx0aC5jb20iLCJ1c2VyaWQiOiIxN2I2YTI3OS1kMzc2LTExZWQtYmVmMi00YjE3MjNjMTExMGIiLCJmaXJzdG5hbWUiOiJnYXlhdHJpIiwibGFzdG5hbWUiOiJoYXJpc2hjaGFuZHJlIn0.Jy75z1w2WLjF-lIOUUUt8or2GE8gWbh3_WT0ILKO0pSXsNU_5h8PTt-6shdoyTmyhcLGDn-r-1sweBmKn_Ptq7nlS1SaXaN_9XCO7lxC14jkzOWulGpmu_eCZyRQ3Zm1pxrLnwfhHzFNnWCqdTvpXFN-KLOYku8TUA1qfJYQySS2l5hmrxHkacUBIaMl5zj39CzmVu1quga7vdeGud5qoxt5q3iEveitjJ7QX1qmrv9alJT2CcCjN-cpHpac_vzUL5PO-rx-VU56LZyNlZ4GJYuz-IGkfffWKGbWw9C34GixJIm3P74Vyx5RQCuKE1Jgq1tJaLR2hGF92cCbJHuA_A"
  //   return this.http.get<any>(environment.myqone_url + "/patient/intake/getpatientmobile/" + pid + "/" + tid)
  //     // {
  //     //   headers: new HttpHeaders().set('Authorization', 'Bearer ' + token)
  //     // });

  // }

  getPatientAppointmentInfo(id: any) {
    return this.http.get<any>(environment.qappoints_url + "/intake/getAppointmentDetails" + "/" + id)
  }

  getSessionTimeOut() {
    return this.http.get(environment.auth_url + `/applications/getApplicationSetting/IDLE_SESSION_TIMEOUT`)
  }

  //get patient registration details

  getPatientRegistrationDetails(sid: any) {
    return this.http.get(environment.qintake_url + `/queue/getUpdatedAcceptanceStatus/${sid}`)
  }

  updateCompleteToApprove(sessionId,patientId) {
    return this.http.post(environment.qintake_url + `/queue/messagequeue/save/${sessionId}/${patientId}`,null)
  }

  getReportDetails(object: any) {
    return this.http.post(environment.qintake_url + `/config/reportdetails`, object);
  }

  changeReportStatus(object: any) {
    return this.http.put(environment.qintake_url + `/config/changestatus`, object);
  }

  getClosingMessage(tenantid: any, appointid: any) {
    return this.http.get(environment.qintake_url + `/closingtext/v2/textdetails/${tenantid}/${appointid}`)
  }

  //Get Pre-registration message detail
  getMessageDetails(payload: messageModel) {
    return this.http.post(environment.qintake_url + `/messagequeue/getAll`, payload);
  }

  //Update message message status
  updateMessageStatus(payload: any) {
    return this.http.post(environment.qintake_url + `/messagequeue/saveStatus`, payload);
  }
  getAllAppointmentTypesSelectedByTenantId(tenantID: string,locationid:any) {
    return this.http.get(environment.qintake_url + `/config/appttype/getAllTabletEnabled/${tenantID}/${locationid}`)
  }

  getAllQIntakeAppointments(tenant: any) {
    return this.http.post(environment.qappoints_url + `/intake/appointmenttypelist`,tenant)
  }

  getTenantStatus(tenantId: any) {
    return this.http.get(environment.qintake_url + `/config/tenant/gettenantconfig/${tenantId}`)
  }

  getPCP() {
    return this.http.get(environment.qintake_url + `/referral/getAllReferringProviders`)
  }

  getExistingPCP(patientid: any, tenantid: any) {
    return this.http.get(environment.qintake_url + `/pcp/${patientid}/${tenantid}`)
  }

  savePCPToQueue(obj: any) {
    return this.http.post(environment.qintake_url + `/pcp/save`, obj)
  }

  getPCPBySessionId(sid: any) {
    return this.http.get(environment.qintake_url + `/pcp/name/${sid}`)
  }

  updatePCP(obj: any) {
    return this.http.put(environment.qintake_url + `/pcp/update`, obj)
  }

  searchPCP(input: any) {
    return this.http.post(environment.qintake_url + `/pcp/search`,input)
  }

  deletePCP(sid: any, contactId: any) {
    return this.http.delete(environment.qintake_url + `/pcp/delete/${sid}/${contactId}`)
  }
  getAllPreferredPharmacy(payload: any) {
    return this.http.get(environment.myqone_url + `/directoryservice/intake/pharmacieslist?speciality=${payload?.pspeciality}&name=${payload?.namesearch}&city=${payload?.citysearch}&state=${payload?.statesearch}&zip=${payload?.zipsearch}&servicelevel=${payload?.pservicelvl}&isactivepharmacy=${payload?.isactivepharmacy}&page=${payload?.page}&size=${payload?.plimit}` );
  }

  getExistingPreferredPharmacy(sessionId: any) {
    return this.http.get(environment.qintake_url + `/pharmacy/getPatientPharmacyList/${sessionId}`);
  }

  getEMRExistingPreferredPharmacy(tenantid: any, patientid: any) {
    return this.http.get(environment.patient_url + `/patientpharmacy/intake/getIntakePatientPharmacyList/${tenantid}/${patientid}`);
  }

  getPharmaciesByLogicalId(payload: any) {
    return this.http.post(environment.myqone_url + `/directoryservice/getPharmaciesByLogicalidOrNcpdpid`, payload);
  }

  getPharmaciesByLogicalIdLocal(contactId: string,tenantId: string) {
    return this.http.get(environment.myqone_url + `/directoryservice/intake/getlocalPharmacybyscontactid/${contactId}/${tenantId}`);
  }

  savePreferredPharmacy(payload: any) {
    return this.http.post(environment.qintake_url + `/pharmacy/save`, payload);
  }

  updataPreferredPharmacy(payload: any) {
    return this.http.put(environment.qintake_url + "/pharmacy/update", payload)
  }

  deletePreferredPharmcay(sessionid: any, patientid: any) {
    return this.http.delete(environment.qintake_url + `/pharmacy/delete/${sessionid}/${patientid}`);
  }

  //new Code
  getEMRExistingReferringProvider(appointmentid: any, tenantid: any) {
    return this.http.get(environment.myqone_url + `/qappointments/intake/referraldetail/${appointmentid}/${tenantid}`);
  }

  saveReferralPRovider(payload: any) {
    return this.http.post(environment.qintake_url + `/referral/save`, payload)
  }

  getExistingReferralProvider(sessionId: any) {
    return this.http.get(environment.qintake_url + `/referral/details/${sessionId}`);
  }

  getAllReferralProvider() {
    return this.http.get(environment.qintake_url + `/referral/getAllReferringProviders`)
  }

  getReferralProviderByContactId(id: any) {
    return this.http.get(environment.qintake_url + `/referral/contact/detail/${id}`);
  }

  updateReferralProvider(payload: any) {
    return this.http.put(environment.qintake_url + "/referral/update", payload)
  }

  deleteReferralProvider(sessionid: any, contactId: any) {
    return this.http.delete(environment.qintake_url + `/referral/delete/${sessionid}/${contactId}`);
  }
  searchReferralProvider(input: any) {
    return this.http.post(environment.qintake_url + `/referral/search`,input)
  }
  getUsageData(tenantid:any,start:any,end:any){
    return this.http.get(environment.qintake_url+`/session/usagereport/${tenantid}/${start}/${end}`)
  }

  getBillingStatus(tenantid:any,patientid:any){
    return this.http.get(environment.qintake_url+`/queue/insurance/billing/${tenantid}/${patientid}`)
  }
  generateSSO(tenantid:any, obj:any){
    return this.http.post(environment.pebackend_url+`cleargageconfig/getcleargagepaymentdetailsbytenantid/${tenantid}`,obj)
  }
  // generateSSO(tenantid: any, obj: any) {
  //   return this.http.post(
  //     `${environment.pebackend_url}cleargageconfig/getcleargagepaymentdetailsbytenantid/${tenantid}`,
  //     obj,
  //     {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json'
  //       }),
  //       withCredentials: true // Enables credentials (cookies/sessions)
  //     }
  //   );
  // }
  
  
  getPaymentDetailsBySession(sessionid:any ){
    return this.http.get(environment.pebackend_url+`cleargageconfig/getcleargagepaymentdetails/${sessionid}` );
  }

  skipPaymentBySession(sessionid: any,skip: boolean) {
    return this.http.post(environment.qintake_url + `/session/skippayment?sessionid=${sessionid}&paymentSkipped=${skip}`, null)
  }

  // getPaymentReport(pid:any,fromdate:any,todate:any,search:any,tid:any){
  //   return this.http.get(environment.pebackend_url+`cleargageconfig/getcleargageconfigreport?patientid=${pid}&fromDate=${fromdate}&toDate=${todate}&search=${search}&tenantid=${tid}`)
  // }
  getPaymentReport(params: { patientid: any, fromDate: any, toDate: any, search: any, tenantid: any }) {
    const queryParams = new HttpParams({ fromObject: params });
  
    return this.http.get(`${environment.pebackend_url}cleargageconfig/getcleargageconfigreport`, { params: queryParams });
  }

  getUsageReport(data:any){
    return this.http.post(environment.qintake_url+`/session/intakecounts`,data)
  }
}
    