import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { PracticeService } from 'src/app/services/practice.service';

@Component({
  selector: 'app-welcome-header',
  templateUrl: './welcome-header.component.html',
  styleUrls: ['./welcome-header.component.scss'],
})
export class WelcomeHeaderComponent implements OnInit {
  isDesktop: boolean;
  ismobile: boolean;
  breakpoint: any = window.matchMedia('(min-width: 768px)');
  mobileview: any = window.matchMedia('(max-width: 768px)');
  tenantId: any;
  Practicename: any;
  practiceData: any;
  sid: any;
  @Input('patientName') patientName: string = '';
  
  constructor(public practiceService: PracticeService, public patientService: PatientService, private commonService: CommonService) {

    this.isDesktop = !!this.breakpoint.matches;
    this.ismobile = !!this.mobileview.matches;
  }

  ngOnInit() {
    const TenantId =  this.commonService.getSessionStorage('tid');
    this.sid =  this.commonService.getSessionStorage('sid');
    this.getPracticeDetails(TenantId);
    this.getAppointmentDetails();
  }

  //Display PracticeName on Header
  getPracticeDetails(TenantId: any) {
    this.practiceService.getPracticeDetailsByTenantId(TenantId).subscribe((res: any) => {
      if (res) {
        this.practiceData = res;
        this.commonService.setSessionStorage('tn',JSON.stringify(this.practiceData))
        let appointment = {
          praticeName: res.tenantname,
          addressline1: res.tenantOrganization[0].tenantOrganizationAddress.addressline1,
          workphone: res?.tenantOrganization[0]?.telecom.workphone
        }
        // localStorage.setItem("appointmentdetails", JSON.stringify(appointment))
      }
    });
  }
 
  //Display PracticeName on login header 
  getAppointmentDetails() {
    this.patientService.getAppointmentDetails(this.sid).subscribe((res: any) => {
      if (res) {
        this.tenantId = res.tenantid;
      }
      this.practiceService.getPracticeDetailsByTenantId(this.tenantId).subscribe((res: any) => {
        if (res) {
          this.practiceData = res;
        }
      });
    });
  }
}

