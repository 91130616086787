import { Component, HostListener, Input, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { CommonService } from 'src/app/core/services/common.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Workbook } from 'exceljs';
import * as fs from "file-saver";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
@Component({
  selector: 'app-usage-report',
  templateUrl: './usage-report.component.html',
  styleUrls: ['./usage-report.component.scss'],
})
export class UsageReportComponent implements OnInit {
  tenantid: any;
  usageData: any;
  public gridData: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public sort: SortDescriptor[] = [{
    field: 'name',
    dir: 'asc'
  }];
  isPage: boolean = false;
  usageReportForm!: FormGroup;
  range: any = { start: null, end: null };
  fromDate: any;
  toDate: any;
  startdata: string;
  endDatdata: string;
  loadingPanelVisible: boolean = false;
  isDateRangeError: boolean = false;
  isDateRangeValid: boolean = true;
  isDateInRange: boolean=false;
  title='Usage Report';
  @Input('usageReport') usageReport: any;
  isTablet: boolean;
  pageOn: boolean = false;
  constructor(private patientService: PatientService,
    private commonservice: CommonService,
    private formBuilder: FormBuilder, 
    private deviceService: DeviceDetectorService,
    private datePipe: DatePipe,
    private dashboardService: DashboardStoreService,
    private router: Router) { 
    this.usageReportForm = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
      tenantId: ['']
    })
    // check device is tablet or not
    this.checkDevice();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkDevice();
  }

  checkDevice() {
    const width = window.innerWidth;
    this.isTablet = width >= 768 && width <= 1024; // Adjust based on your tablet breakpoints
  }

  ngOnInit() {
    this.tenantid = this.commonservice.getSessionStorage('tnt')
    this.commonservice.setSessionStorage("redirectTo", JSON.stringify('usagereport'));
    if(this.router.url == '/clinic-dashboard') 
        this.pageOn = true;
    this.setDefaultDate();
   
  }

  getUsageData() {
    this.dashboardService.usageObservable$.subscribe((data) => {
      this.usageReport = data;
      let value = {
        appointmenttypedescription: this.usageReport.appointmentType?.appointmenttypedescription || "",
    sourceworkflow: "Clinic", // Static value as per requirement
    locationname: this.usageReport.location?.length ? this.usageReport.location[0] : "",
    startdate: this.formatDate(this.usageReport.startDate), // Convert date format
    enddate: this.formatDate(this.usageReport.endDate, true), // Convert date format with end of day
    status: this.usageReport.status?.name?.toLowerCase() || ""
      }

      this.patientService.getUsageReport(value).subscribe({
        next: (response: any) => {
          this.usageData = response;
          this.loadData();
        }
      })
    })
  }

  formatDate(dateString: string, isEndOfDay: boolean = false): string {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isEndOfDay) {
      date.setHours(23, 59, 59);
    } else {
      date.setHours(0, 0, 0);
    }
    return date.toISOString().split(".")[0]; // Removes milliseconds
  }
 

  loadData() {
     if (this.usageData?.length != 0) {
      this.isPage = true;
    }else{
      this.isPage = false;
    }
    this.gridData = {
      data: [
        { startDate: new Date(this.usageReport.startDate).toLocaleDateString('en-US'), 
          endDate: new Date(this.usageReport.endDate).toLocaleDateString('en-US'), mobileCount: this.usageData.mobileCount, tabletCount: this.usageData.tabletCount }, 
      ],
      total: 1
    };
      setTimeout(() => {
      this.loadingPanelVisible = false;
    }, 600);

  }

  setDefaultDate() {
    const today = this.datePipe.transform(new Date(), 'MM/dd/yyyy') ; 
    this.usageReportForm.patchValue({
      fromDate: today,
      toDate: today,
    });
    this.range.start = this.fromDate;
    this.range.end = this.toDate;
    this.isDateRangeError = false;
    this.isDateRangeValid = true;
    this.isDateInRange = false;
    this.getUsageData();
  }

  fromDateValueChange(fromDate: any) {
    this.range.start = fromDate;
    this.fromDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd')
    if (this.datePipe.transform(this.range.start, 'yyyy-MM-dd') > this.datePipe.transform(this.range.end, 'yyyy-MM-dd')) {
      this.isDateRangeError = true;
    } else {
      this.isDateRangeError = false;
    }
    const timeDifference = Math.abs(this.range.end.getTime() - this.range.start.getTime());
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    if (dayDifference > 90) {
     
      this.isDateInRange =true;
    }else{
      this.isDateInRange =false;
    }
    if (this.range.start === null || this.range.end === null) {
      this.isDateRangeValid = this.range.start === null && this.range.end === null;
    } else {
      this.isDateRangeValid = true;
    }
  }

  toDateValueChange(toDate: any) {
    this.range.end = toDate;
    this.toDate = this.datePipe.transform(toDate, 'yyyy-MM-dd')
    if (this.datePipe.transform(this.range.start, 'yyyy-MM-dd') > this.datePipe.transform(this.range.end, 'yyyy-MM-dd')) {
      this.isDateRangeError = true;
    } else {
      this.isDateRangeError = false;
    }
    const timeDifference = Math.abs(this.range.end.getTime() - this.range.start.getTime());
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    if (dayDifference > 90) {
      
      this.isDateInRange = true;
    }else{
      this.isDateInRange = false;
    }
    if (this.range.start === null || this.range.end === null) {
      this.isDateRangeValid = this.range.start === null && this.range.end === null;
    } else {
      this.isDateRangeValid = true;
    }
  }

  resetfilter() {
    this.setDefaultDate();
  }

  applyFilter() {
    this.loadingPanelVisible = true;
    const payload = this.usageReportForm.value;
    const fromDate = this.datePipe.transform(payload.fromDate, 'yyyy-MM-dd');
    const toDate = this.datePipe.transform(payload.toDate, 'yyyy-MM-dd');

    this.patientService.getUsageData(this.tenantid, fromDate, toDate).subscribe({
      next: (res: any) => {
        this.usageData = res;
        this.loadingPanelVisible = false;
      },
      error: (err) => {
        this.loadingPanelVisible = false;
        console.error("Error fetching usage data", err);
      },
      complete: () => {
        this.loadData();
      }
    });
  }

  exportUserList() {
    if (this.isPage == true) {
      const header: string[] = ["start date", "end date", "mobile", "tablet"];
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Usage Report");
      const headerRow = worksheet.addRow(header);
      headerRow.font = {
        family: 4,
        size: 11,
        bold: true,
      };
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffffff" },
          bgColor: { argb: "ffffff" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
      // usagedata error for each not function
      this.usageData = [
        { startDate: new Date(this.usageReport.startDate).toLocaleDateString('en-US'), 
          endDate: new Date(this.usageReport.endDate).toLocaleDateString('en-US'), mobileCount: this.usageData.mobileCount, tabletCount: this.usageData.tabletCount }, 
      ]
      this.usageData.forEach((d) => {
        let newArr = [];
        newArr[0] = d?.startDate
        newArr[1] = d?.endDate
        newArr[2] = d?.mobileCount;
        newArr[3] = d?.tabletCount;

        const row = worksheet.addRow(newArr);
        row.eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffffff" },
            bgColor: { argb: "ffffff" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        })
      });

      worksheet.getColumn(1).width = 30;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 15;
      worksheet.getColumn(4).width = 15;

      workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const current = new Date();
        const timestamp = current.getTime();
        let fileName = "Message Usage Report " + timestamp;
        fs.saveAs(blob, fileName + `.xlsx`);
      });
    }

  }

  
  isSidebarVisible: boolean = false;

  onSidebarToggle(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  } 

  activeTab: string = 'appointment';
  isVisibleUsage = true;
  isVisibleAudit = true;
 
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
}

