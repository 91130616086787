import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  token: any;
  private updateFunctionSubject = new Subject<void>();
  updateFunction$ = this.updateFunctionSubject.asObservable();

  private updateInsuranceSubject = new Subject<void>();
  updateIndurance$ = this.updateInsuranceSubject.asObservable();

  private itemList = new Subject<void>();
  itemList$ = this.itemList.asObservable();

  private sessionList = new Subject<void>();
  sessionList$ = this.sessionList.asObservable();

  private insuranceList = new Subject<void>();
  insuranceList$ = this.insuranceList.asObservable();

  private updateStatusSubject = new BehaviorSubject<boolean>(false);
  updateStatus$ = this.updateStatusSubject.asObservable();

  private updateInsuranceStatus = new BehaviorSubject<boolean>(false);
  updateInsuranceStatus$ = this.updateInsuranceStatus.asObservable();

  constructor(private http: HttpClient) { }

  // private getToken(): string {
  //   this.token = sessionStorage.getItem('PI');
  //   const parsedToken = JSON.parse(this.token);
  //   return parsedToken.token;
  // }

  // getProviderByAppointment(patientId: any, tenantId: any): Observable<any> {
  //   const usetoken = this.getToken();
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + usetoken
  //     })
  //   }
  //   return this.http.get<any>(environment.qappoints_url + "/facesheet/getPatientAppointments/" + patientId + "/" + tenantId, httpOptions);

  // }


  triggerUpdateFunction() {
    this.updateFunctionSubject.next();
  }

  triggerInsuranceFunction() {
    this.updateInsuranceSubject.next();
  }

  refreshData(res: any) {
    this.updateList(res);
    this.setResultTrue();
    this.setResultTrue1();
    this.setInsuranceUpdateStatus(true);
    this.triggerUpdateList();
  }

  updateList(newList: any) {
    this.itemList.next(newList);
  }

  updatesessionList(newList: any) {
    this.sessionList.next(newList);

  }
  updateInsuranceList(newList: any) {
    this.insuranceList.next(newList);
  }
  setUpdateStatus(status: boolean) {
    this.updateStatusSubject.next(status);
  }

  setInsuranceUpdateStatus(status: boolean) {
    this.updateInsuranceStatus.next(status);
  }


  // enable/disable of demo button
  private formIsValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  updateFormValidity(isValid: boolean) {
    this.formIsValidSubject.next(isValid);
  }
  getFormValidity() {
    return this.formIsValidSubject.asObservable();
  }

  // enable/disable of insu button

  private insuFromIsValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  updateInsuValidity(isValid: boolean) {
    this.insuFromIsValidSubject.next(isValid);
  }

  getInsuValidity() {
    return this.insuFromIsValidSubject.asObservable();
  }

  private loaderClickSource = new Subject<void>();
  loaderClick$ = this.loaderClickSource.asObservable();

  triggerLoaderClick() {
    this.loaderClickSource.next();
  }

  private isResultTrueSubject = new BehaviorSubject<boolean>(false);
  isResultTrue$ = this.isResultTrueSubject.asObservable();

  setResultTrue() {
    this.isResultTrueSubject.next(true);
  }

  private isVariableFalseSource = new BehaviorSubject<boolean>(true);
  isVariableFalse$ = this.isVariableFalseSource.asObservable()

  setIsVariableFalse(value: boolean) {
    this.isVariableFalseSource.next(value);
  }

  public updateEvent: EventEmitter<void> = new EventEmitter<void>();

  emitUpdateEvent(): void {
    this.updateEvent.emit();
  }
  private isResultTrueSubject1 = new BehaviorSubject<boolean>(true);
  isResultTrue1$ = this.isResultTrueSubject1.asObservable();

  setResultTrue1() {
    this.isResultTrueSubject1.next(false);
  }
  public updateListEvent: EventEmitter<void> = new EventEmitter<void>();

  triggerUpdateList(): void {
    // Emit the event to notify other components
    this.updateListEvent.emit();
  }
  private loadercomparision = new Subject<void>();
  loaderClick1$ = this.loadercomparision.asObservable();

  triggerLoaderClick1() {
    this.loaderClickSource.next();
  }

  private insuranceObjectSource = new BehaviorSubject<any>(null);
  insuranceObject$ = this.insuranceObjectSource.asObservable();

  setInsuranceObject(insuranceObject: any) {
    this.insuranceObjectSource.next(insuranceObject);
  }

  private insuranceObjectSource1 = new BehaviorSubject<any>(null);
  insuranceObject1$ = this.insuranceObjectSource1.asObservable();

  setInsuranceObject1(insuranceObject: any) {
    this.insuranceObjectSource1.next(insuranceObject);
  }

  private pcpSubject = new BehaviorSubject<any>(null);
  
  currentPcp$ = this.pcpSubject.asObservable();

  updatePcp(pcp: any) {
    this.pcpSubject.next(pcp);
  }

  private referralSubject = new BehaviorSubject<any>(null);
  
  currentReferral$ = this.referralSubject.asObservable();

  updatereferral(referral: any) {
    this.referralSubject.next(referral);
  }

}
