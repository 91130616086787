 

<div class="l-fill">
  <div>
    <app-clinic-dashboard-header [isSidebarVisible]="isSidebarVisible" (sidebarToggle)="onSidebarToggle()"></app-clinic-dashboard-header>
  </div>

  <div class="d-flex">
    <div class="l-fill" [ngClass]="{ 'sidebar-visible': isSidebarVisible, 'sidebar-hidden': !isSidebarVisible }">

      <app-clinic-dashboard-sidebar [activeTab]="activeTab" (tabChange)="setActiveTab($event)"></app-clinic-dashboard-sidebar>
       
      
    </div>
    <div class="l-fill w-100 overflow-y-hidden">
      <div class="clinic-dashboard-main main_container main-content" style="background-color: #fff;"
        *ngIf="activeTab === 'appointment'"> 
        <div class="d-flex">
          <app-clinic-dashboard-provider-list></app-clinic-dashboard-provider-list>
          <app-clinic-dashboard-patient-list class="w-100"></app-clinic-dashboard-patient-list>
        </div>

      </div>
      <div class="usage-report content" *ngIf="activeTab === 'usage-report'">
        <app-usage-report></app-usage-report> 
      </div>
      <div class="usage-report content" *ngIf="activeTab === 'audit-report'">
        <app-audit-report></app-audit-report>
      </div>
    </div>
  </div>


</div>

<app-idle></app-idle>
