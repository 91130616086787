import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Route, Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';
import { LoginService } from 'src/app/core/tablet-service/login.service';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';

@Component({
  selector: 'app-logout-avatar',
  templateUrl: './logout-avatar.component.html',
  styleUrls: ['./logout-avatar.component.scss'],
})
export class LogoutAvatarComponent implements OnInit {

  tenantLength: any[] = [];
  allRoles: Array<any> = [];
  auditRole: boolean = false;
  userRole: boolean = false;
  reportShow: String = '';
  constructor(
    private dashboardService: DashboardStoreService,
    private router: Router,
    private loginService: LoginService,
    private parms: NavParams,
    private popOverCtrl: PopoverController,
    private auditService: AuditService,
    private commonService: CommonService,
   ) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.popOverCtrl.dismiss().catch(() => {
        });
   }
  })
  }

  ngOnInit() {
    this.tenantLength.push(...this.parms.data['tenant']); 
        if(this.router.url == "/app/usage-report")
          this.reportShow = 'User Report'
        if(this.router.url == "/app/audit-report")
      this.reportShow = 'Audit Report' 
    this.allRoles = this.commonService.getSessionStorage('roles'); 
    this.auditRole = !!this.allRoles.includes("Audit Report");
    this.userRole = !!this.allRoles.includes("Audit Report");
  }

  removetenant() {
    this.auditService.writeOparationalLog('Change Practice#' + 0);
    this.popOverCtrl.dismiss()
    let location: any[] = [];
    this.dashboardService.locationBehaviour.next(location)
    this.commonService.setLocalStorage('changePractice', true);
    this.router.navigate(['/practice-list']);

  }

  navigateToAuditReport() {
    // navigate to audit
    this.router.navigateByUrl("/app/audit-report")
    this.popOverCtrl.dismiss()
  }

  navigateToUsageReport() {
    // navigate to user
    this.router.navigateByUrl("/app/usage-report")
    this.popOverCtrl.dismiss()
  }

  logout() {
    this.auditService.writeOparationalLog('Logout#' + 0);
    this.popOverCtrl.dismiss()
    this.loginService.logout()
  }
}