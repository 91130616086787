
<div class="confirm_patient_main d-flex align-items-center justify-content-center main_container">
  <div class="editInfo_popup">
  <!-- <app-pre-registration-welcome [patient]="patients"></app-pre-registration-welcome> -->

    <!-- <kendo-dialog [hidden]="!isConfirm"> -->





      <div class="confirm_patient_popup">
        <!-- <div class="d-flex justify-content-center pt-30 align-items-center">
          <ion-avatar class="default-avatar" slot="start">
            <img src="../../../../assets/avatar/avatar-01.jpg" />
          </ion-avatar>
          <div class="">
            <div class="line-13">
              <ion-text class="font16 line-15 text-blue-color" color="text-blue-color">Charlotte</ion-text>
            </div>
            <div class="line-15">
              <ion-text class="font16 bold-font-weight line-15 text-blue-color">Nedermeir</ion-text>
            </div>
          </div>
        </div> -->

        <!-- <div class="d-flex justify-content-center pt-10">
          <ion-avatar class="small-avatar" slot="start">
            <img src="../../../../assets/avatar/avatar-01.jpg" />
          </ion-avatar>
          <div class="">
            <div class="line-13">
              <ion-text class="font16 line-15 text-blue-color" color="text-blue-color">Charlotte</ion-text>
            </div>
            <div class="line-15">
              <ion-text class="font16 bold-font-weight line-15 text-blue-color">Nedermeir</ion-text>
            </div>
          </div>
        </div> -->

        <!-- <div class="lock_icon_main d-flex align-items-center justify-content-center mb-20 pt-20">
          <ion-icon src="../../../../../assets/svg/lock.svg"  class="lock_icon d-block mr-5" color="white"></ion-icon>
          <ion-text class="bodyM-txt-bold text-blue-color" >This device is locked</ion-text>
        </div>
        <div class="text-center mb-25">
          <ion-text class="bodyL-txt montserrat" color="gray100">Return to <span class="bold-font-weight">front desk</span> or verify your <span  class="bold-font-weight ion-color-primarydk">date of birth</span> to continue</ion-text>
        </div> -->


        <div class="pt-30 text-center mb-25"  *ngIf="isConfirm">
          <ion-text class="bodyL-txt montserrat" color="gray100">Verify your <span class="bold-font-weight">date of birth</span> to continue</ion-text>
        </div>


        <!-- <div class="text-center pt-30" *ngIf="!isConfirm">
          <div class="mb-5"><ion-text class="font16 line-16 ion-color-error100" color="error100">This does not match the date of birth we have on file for you</ion-text>
          </div>
          <div><ion-text class="font16 line-16" color="gray80">Try again or ask the front desk for assistance</ion-text></div>
        </div> -->
        <!-- <div class="pt-30 text-center">
          <ion-text class="bodyL-txt montserrat" color="gray100">Verify your <span class="bold-font-weight dob-text">date of birth</span> to continue</ion-text>
        </div> -->
        <form class="k-form"  [formGroup]="confirmationform">
          <div class="pt-20 text-center">
            <kendo-formfield>
              <kendo-textbox (paste)="disableEvent($event)" 
              (copy)="disableEvent($event)" class="h3 reads"  formControlName="dob"  placeholder="MM/DD/YYYY" #dobInput></kendo-textbox>
              
              <div *ngIf="confirmationform.get('dob')?.hasError('invalidDate')">
                <ion-text color="error100 bodyM-txt class de">Invalid date.</ion-text>
              </div>
              <kendo-formerror></kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="pin_keypad pt-30 p-10">
            <div><button kendoButton class="bodyXL-txt mr-15 mb-15" (click)="onKeypadClick(1)">1</button></div>
            <div><button kendoButton class="bodyXL-txt mr-15 mb-15" (click)="onKeypadClick(2)">2</button></div>
            <div><button kendoButton class="bodyXL-txt mb-15" (click)="onKeypadClick(3)">3</button></div>
            <div><button kendoButton class="bodyXL-txt mr-15 mb-15" (click)="onKeypadClick(4)">4</button></div>
            <div><button kendoButton class="bodyXL-txt mr-15 mb-15" (click)="onKeypadClick(5)">5</button></div>
            <div><button kendoButton class="bodyXL-txt mb-15" (click)="onKeypadClick(6)">6</button></div>
            <div><button kendoButton class="bodyXL-txt mr-15 mb-15" (click)="onKeypadClick(7)">7</button></div>
            <div><button kendoButton class="bodyXL-txt mr-15 mb-15" (click)="onKeypadClick(8)">8</button></div>
            <div><button kendoButton class="bodyXL-txt mb-15" (click)="onKeypadClick(9)">9</button></div>
            <div></div>
            <div><button kendoButton class="bodyXL-txt mr-15" (click)="onKeypadClick(0)">0</button></div>
            <div (click)="onDelete()" class="d-flex align-items-center justify-content-center pointer">
              <ion-icon class="h3" src="../../../../assets/svg/backspace_24.svg" color="gray80"></ion-icon>
            </div>
          </div>
          <div class="error_msg d-flex justify-content-center align-items-center pt-16 px-25" *ngIf="errorMessage">
            <div class="line-20"><ion-icon class="mr-5" src="../../../../assets/svg/alert-circle.svg" ></ion-icon></div>
            <div class="line-20"><ion-text color="error100" class="bodyM-txt">{{errorMessage}}</ion-text></div>
          </div>
          <div class="row w-100 ion-justify-content-center align-items-center pt-16">
            <ion-button *ngIf="!isClicked"  fill="solid"  [disabled]="confirmationform.get('dob')?.invalid"  color="ion-color-white" class="filled-icon-btn capitalize confirm_verify_btn" (click)="verify()">
              <ion-text>Verify</ion-text>
              <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
            </ion-button>

            <ion-button *ngIf="isClicked"  fill="solid"   color="ion-color-white" class="filled-icon-btn capitalize confirm_verify_btn">
              <ion-text>Verify</ion-text>
              <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
            </ion-button>
          </div>
        </form>
        <div class="d-flex align-items-center justify-content-center pt-30">
          <div class="cursor" (click)="navigateToLogin()">
            <div class="line-10 text-center"><ion-icon src="../../../../assets/svg/medical-personnel-doctor.svg" class="h2" color="gray80"></ion-icon></div>
            <div class="line-10 text-center"><ion-text class="caption" color="gray80">admin</ion-text></div>
          </div>
        </div>
      </div>
    <!-- </kendo-dialog> -->
  </div>
</div>

<app-idle></app-idle>
