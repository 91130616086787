<div class="intake-filter" >
         <div class="p-15"  *ngIf="hasAppointmentPermission">
           <div class="appt cursor d-flex align-items-center w-100 px-10 py-8" (click)="selectTab('appointment')" (click)="showTab('appointment')" [ngClass]="{'tab-selected': selectedTab === 'appointment'}" [style.borderRadius]="selectedTab === 'appointment' ? '5px' : ''" >
             <ion-icon class="title-32-font-size mr-10" src="../../../../../../assets/svg/1_calender.svg"></ion-icon>
             <ion-text class="h5-M">Appointments</ion-text>
           </div>
         </div>
 
         <div class="p-15" *ngIf="hasUsageReportPermission || hasAuditReportPermission">
           <ion-text class="bodyS-txt uppercase p-10 pb-0">Reports</ion-text>
           <div *ngIf="hasUsageReportPermission">
             <div class="usage-report d-flex align-items-center cursor mt-10" [ngClass]="{'report-selected-tab':  isVisibleUsage}"
             [style.borderRadius]="isVisibleUsage ? '5px 5px 0 0' : ''"
             (click)="selectTab('usage-report')" (click)="showTab('usage-report')">
               <div class="d-flex align-items-center w-100 px-10 py-8">
                 <ion-icon class="title-32-font-size mr-10"
                   src="../../../../assets/svg/1_file-text.svg"></ion-icon>
                 <ion-text class="h5">Usage Report</ion-text>
               </div>
               <div class="line-hight-11">
                 <ion-icon class="bodyL-txt p-10" color="gray60" *ngIf="!isVisibleUsage"
                   src="../../../../assets/svg/chevron-down.svg"></ion-icon>
 
                   <ion-icon class="bodyL-txt p-10" color="gray60" *ngIf="isVisibleUsage"
                   src="../../../../assets/svg/chevron-up.svg"></ion-icon>
               </div>
             </div>
             <div>
              <form [formGroup]="filterForm" (ngSubmit)="applyFilter()">
                <div class="filter-usage" *ngIf="isVisibleUsage">
                  <div class="status-form">
                    <div class="wrapper mb-20 mobile-width">
                      <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text color="gray60">Date
                          Range</ion-text></label><br>
                      <kendo-daterange class="d-flex align-items-center ion-justify-content-between" #daterange>
                        <kendo-dateinput placeholder="From" color="gray100" class="body2-txt" kendoDateRangeStartInput
                          [(value)]="range.start" (blur)="showDateCancel()" formControlName="startDate"></kendo-dateinput>
                        <ion-text class="mx-15" color="gray100" class="body2-txt">
                          &#8212;
                        </ion-text>
                        <kendo-dateinput placeholder="To" kendoDateRangeEndInput [(value)]="range.end"
                          (blur)="showDateCancel()" color="gray100" class="body2-txt" formControlName="endDate"></kendo-dateinput>
                      </kendo-daterange>
                    </div>
                  </div>
    
                  <div class="status-form">
                    <div class="wrapper mb-20 mobile-width">
                      <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text
                          color="gray60">Status</ion-text></label><br>
                      <kendo-combobox [data]="StatusUsage" valueField="id" textField="name"  formControlName="status" placeholder="All statuses">
                      </kendo-combobox>
                    </div>
                  </div>
    
                  <div class="Appt-field">
                    <div class="wrapper mb-20 mobile-width">
                      <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text color="gray60">Appointment
                          Type</ion-text></label><br>
                      <kendo-combobox [data]="ApptType"  formControlName="appointmentType" valueField="appointmenttypeid" textField="appointmenttypedescription" placeholder="All types">
                      </kendo-combobox>
                    </div>
                  </div>
    
                  <div class="completion-field">
                    <div class="wrapper mb-20 mobile-width">
                      <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text color="gray60">Mode of
                          completion</ion-text></label><br>
                      <kendo-combobox [data]="Completion" valueField="id" textField="name" formControlName="completionMode" placeholder="All statuses">
                      </kendo-combobox>
                    </div>
                  </div>
    
                  <div class="location-field">
                    <div class="wrapper mb-20 mobile-width">
                      <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text
                          color="gray60">Location</ion-text></label><br>
                      <kendo-multiselect [data]="listItems" [(ngModel)]="value" placeholder="All Statuses"
                        [autoClose]="false" #focuslist class="custom-multiselect"  formControlName="location">
                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <label>
                            <div class="d-flex align-items-center">
                              <input type="checkbox" kendoCheckBox class="customCheckbox mr-5"
                                [checked]="value.includes(dataItem)" />
                              <ion-text class="body1-txt" color="gray80">{{ dataItem }}</ion-text>
                            </div>
                          </label>
                        </ng-template>
                      </kendo-multiselect>
                    </div>
                  </div>
    
                  <div class="d-flex pat_reg">
                   <ion-button type="button" fill="outline" color="highlight" class="filled-outline-btn capitalize info-btn w-100 mr-10"
                   (click)="resetUsageForm()">
                      <ion-text>Reset</ion-text>
                    </ion-button>
    
                    <ion-button fill="solid" expand="block" color="ion-color-white"  [disabled]="filterForm.invalid" class="filled-icon-btn capitalize info-btn w-100" (click)="applyFilter()">
                      <ion-text>Apply</ion-text>
                    </ion-button>
    
                  </div>
    
                </div>
              </form>
             </div>
         
           </div>
           <div *ngIf="hasAuditReportPermission">
             <div class="audit-report d-flex align-items-center cursor mt-10" [ngClass]="{'report-selected-tab': isVisibleAudit}"
             [style.borderRadius]="isVisibleAudit ? '5px 5px 0 0' : ''"
             (click)="selectTab('audit-report')" [class]="auditSelected"
               (click)="showTab('audit-report')">
               <div class="d-flex align-items-center w-100 px-10 py-8">
                 <ion-icon class="title-32-font-size mr-10"
                   src="../../../../assets/svg/1_file-text.svg"></ion-icon>
                 <ion-text class="h5">Audit Log</ion-text>
               </div>
               <div class="line-hight-11">
                 <ion-icon class="bodyL-txt p-10" color="gray60" *ngIf="!isVisibleAudit"
                   src="../../../../assets/svg/chevron-down.svg"></ion-icon>
                   
                   <ion-icon class="bodyL-txt p-10" color="gray60" *ngIf="isVisibleAudit"
                   src="../../../../assets/svg/chevron-up.svg"></ion-icon>
               </div>
             </div>
             <div>
              <form [formGroup]="auditReportForm">
                <div class="filter-usage" *ngIf="isVisibleAudit">
    
                  <div class="status-form">
                    <div class="wrapper mb-20 mobile-width">
                      <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text
                          color="gray60">Status</ion-text></label>
                      <kendo-combobox [data]="StatusAudit" formControlName="status" valueField="id" textField="name" placeholder="All statuses">
                      </kendo-combobox>
                    </div>
                  </div> 
    
                  <div class="status-form">
                    <div class="wrapper mb-20 mobile-width">
                      <label class="mb-3 bodyS-txt ion-text-uppercase"><ion-text color="gray60">Date
                          Range</ion-text></label>
                      <kendo-daterange class="d-flex align-items-center ion-justify-content-between" #daterange>
                        <kendo-dateinput placeholder="From" color="gray100" class="body2-txt" kendoDateRangeStartInput
                          [(value)]="range.start" (blur)="showDateCancel()" formControlName="startDate"></kendo-dateinput>
                        <ion-text class="mx-15" color="gray100" class="body2-txt">
                          &#8212;
                        </ion-text>
                        <kendo-dateinput placeholder="To" kendoDateRangeEndInput [(value)]="range.end"
                          (blur)="showDateCancel()" color="gray100" class="body2-txt" formControlName="endDate"></kendo-dateinput>
                      </kendo-daterange>
                    </div>
                  </div>
                  <div class="d-flex pat_reg">
                    <ion-button fill="outline" type="submit"  (click)="resetAuditForm()" color="highlight" class="filled-outline-btn capitalize info-btn w-100 mr-10">
                      <ion-text>Reset</ion-text>
                    </ion-button>
    
                    <ion-button fill="solid" expand="block" color="ion-color-white" [disabled]="auditReportForm.invalid" 
                    (click)="applyFilterAudit()" type="submit"
                      class="filled-icon-btn capitalize info-btn w-100">
                      <ion-text>Apply</ion-text>
                    </ion-button>
    
                  </div>
    
                </div>

              </form>
             </div>
           </div>
         </div>
       </div>