<div class="clinic_lock_screen_main main_container" *ngIf="!isMobile">
  <div class="clinic_lock_form_main" *ngIf="isResetPassword">
    <div class="lock_icon_main column align-items-center justify-content-center mb-40 pt-20">
      <ion-icon src="../../../../../assets/svg/Lock_new.svg"  class="lock_icon d-block mb-10 text-blue-color"></ion-icon>
      <!-- <div class="d-flex align-items-center">
        <div class="mr-5">
          <ion-icon class="lock_icon2" src="../../../../assets/svg/Lock_2.svg" ></ion-icon>
        </div>
        <div>
          <ion-text class="title-32 text-blue-color" color="">This device is locked</ion-text>
        </div>
      </div> -->
    </div>
    <kendo-tabstrip tabPosition="top">
      <!-- <kendo-tabstrip-tab title="Pin" [disabled]="true">
        <ng-template kendoTabContent> -->
          <!-- <div class="text-center pt-30 pb-10">
            <ion-text class="h3" color="white" *ngIf="isIncorrect">Type PIN to unlock</ion-text>
            <ion-text class="bodyXL-txt-bold bold-font-weight montserrat" color="white" *ngIf="!isIncorrect">Incorrect Pin</ion-text>
          </div> -->
          <!-- <div class="pin_form_main p-10  pt-16">
            <div class="d-flex align-items-center justify-content-center px-10"> -->
              <!-- <div class="d-flex align-items-center justify-content-center ">
                <div><kendo-numerictextbox  class="bodyXL-txt-bold mr-10" [maxlength]="maxlength" [format]="'0'" (blur)="showIncorrectPin()" [ngClass]="isPinError == true ? 'red' : 'blue'"></kendo-numerictextbox></div>
                <div><kendo-numerictextbox  class="bodyXL-txt-bold mr-10" [maxlength]="maxlength" [format]="'0'" [ngClass]="isPinError == true ? 'red' : 'blue'"></kendo-numerictextbox></div>
                <div><kendo-numerictextbox  class="bodyXL-txt-bold mr-10" [maxlength]="maxlength" [format]="'0'" [ngClass]="isPinError == true ? 'red' : 'blue'"></kendo-numerictextbox></div>
                <div><kendo-numerictextbox  class="bodyXL-txt-bold" [maxlength]="maxlength" [format]="'0'" (blur)="showPinError()" [ngClass]="isPinError == true ? 'red' : 'blue'"></kendo-numerictextbox></div>
              </div> -->
              <!-- <div >
                <div><ion-icon class="bodyXL-txt ml-10" src="../../../../../assets/svg/rotate-ccw.svg" *ngIf="!isIncorrect"></ion-icon></div>
              </div> -->
            <!-- </div>
            <div class="pin_keypad pt-20 p-10">
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">1</button></div>
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">2</button></div>
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">3</button></div>
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">4</button></div>
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">5</button></div>
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">6</button></div>
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">7</button></div>
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">8</button></div>
              <div><button kendoButton class="bodyXL-txt mr-15 mb-15">9</button></div>
              <div></div>
              <div><button kendoButton class="bodyXL-txt mr-15">0</button></div>
              <div class="d-flex align-items-center justify-content-center">
                <ion-icon class="bodyXL-txt" src="../../../../../assets/svg/backspace_24px.svg"></ion-icon>
              </div>
            </div>
            <div class="error_msg d-flex justify-content-center align-items-center pt-10 px-20" *ngIf="isPinError">
              <div class="line-20"><ion-icon class="mr-5" src="../../../../assets/svg/alert-circle.svg" ></ion-icon></div>
              <div class="line-20"><ion-text color="error100" class="bodyM-txt">Invalid pin. Please check and try again.</ion-text></div>
            </div>
            <div class="pt-10 text-center pinOpenBtn">
              <ion-button fill="solid" color="" class="filled-icon-btn" disabled="true" *ngIf="isIncorrect">
                <ion-text class="capitalize roboto" color="">Open</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-10" color="P_blue"></ion-icon>
              </ion-button>
              <ion-button fill="solid" color="" class="filled-icon-btn" *ngIf="!isIncorrect">
                <ion-text class="capitalize roboto" color="">Open</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-10" color="P_blue"></ion-icon>
              </ion-button>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab> -->
      <kendo-tabstrip-tab title="Log In"  [selected]="true">
        <ng-template kendoTabContent>
          <form class="k-form" class="clinic_signin_form_main" [formGroup]="loginForm">
            <div class="d-flex align-items-center pt-5 borderWhiteBottom  px-20">
              <div class="user_icon pr-10">
                <ion-icon src="../../../../../assets/svg/user-circle.svg"  color="highlight"></ion-icon>
              </div>
              <kendo-textbox formControlName="username"  class="montserrat font20" placeholder="Username"  #username="kendoTextBox"></kendo-textbox>
              <div class="px-10 pr-20 clear_icon cursor" *ngIf="!isForgetLink">
                <ion-icon src="../../../../../assets/svg/x.svg" class="ion-color-gray100 bodyM-txt"></ion-icon>
              </div>
            </div>
            <!-- (blur)="showForgetLink()"  -->
            <div class="d-flex align-items-center pt-20 borderWhiteBottom px-20">
              <div class="user_icon pr-10">
                <ion-icon src="../../../../../assets/svg/user-lock.svg" color="highlight"></ion-icon>
              </div>
              <kendo-textbox  formControlName="password"  placeholder="Password"   type="password" class="montserrat font20" #passwordInput="kendoTextBox"></kendo-textbox> 
              <div class="px-10 pr-20 clear_icon cursor" *ngIf="!isForgetLink">
                <ion-icon src="../../../../../assets/svg/x.svg" class="ion-color-gray100 bodyM-txt"></ion-icon>
              </div>
            </div>
            <div class="error_msg_login d-flex justify-content-center align-items-center pt-20"  *ngIf="errorMessage">
              <ion-icon class="mr-5 title-32-font-size" src="../../../../assets/svg/alert-circle.svg" ></ion-icon>
              <div class="line-20"><ion-text color="error100" class="bodyS-txt">{{errorMessage}}.</ion-text></div>
            </div>
            
            <!-- <div class="pt-25 text-center" *ngIf="isForgetLink">
              <ion-button  fill="solid" color="" class="filled-icon-btn" disabled="true">
                <ion-text class="capitalize roboto" color="P_blue">Open</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-10" color="P_blue"></ion-icon>
              </ion-button>
            </div> -->

            <!-- *ngIf="!isForgetLink" -->
            <div  class="pt-25 text-center" >
              <div class="error_msg d-flex justify-content-center align-items-center pt-20" *ngIf="noPracticeListError1 && noPracticeListError2 ">
                <div class="line-20"><ion-icon class="mr-5" src="../../../../assets/svg/alert-circle.svg"></ion-icon></div>
                <div class="line-20">
                  <ion-text color="error100" class="bodyM-txt d-block">{{ noPracticeListError1 }}</ion-text>
                  <ion-text color="error100" class="bodyM-txt">{{ noPracticeListError2 }}</ion-text>
                </div>
              </div>
              <br>
              <ion-button [disabled]="!loginForm.valid"  (click)="onSubmit()" fill="solid" color="white" class="filled-icon-btn">
                <ion-text class="capitalize roboto">Open</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-10"></ion-icon>
              </ion-button>
            </div>
            <!-- <div class="error_msg d-flex justify-content-center align-items-center pt-20" *ngIf="noPracticeListError">
              <div class="line-20"><ion-icon class="mr-5" src="../../../../assets/svg/alert-circle.svg"></ion-icon></div>
              <div class="line-20"><ion-text color="error100" class="bodyM-txt">{{ noPracticeListError }}</ion-text></div>
            </div> -->
            <!-- <div class="pt-60 text-center cursor" *ngIf="isForgetLink">
              <ion-text class="bodyL-txt-medium montserrat" color="highlight">I forgot my password</ion-text>
            </div> -->
          </form>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
  <div class="clinic_lock_form_main width700 pt-75 reset_email_form" *ngIf="!isResetPassword">
    <div class="text-center reset_heading">
      <ion-text class="h3-Montserrat" color="gray100" *ngIf="isResetPasswordForm">Enter your email to reset your password</ion-text>
      <ion-text class="h3-Montserrat" color="gray100" *ngIf="!isResetPasswordForm">A link was sent to your email to reset your password</ion-text>
    </div>
    <form class="k-form forgetPasswordForm" *ngIf="isResetPasswordForm">
      <div class="d-flex align-items-center justify-content-center borderWhiteBottom  mb-30 p-20 pb-0">
        <div class="user_icon  pr-10 pt-10">
          <ion-icon src="../../../../../assets/svg/Mail.svg"></ion-icon>
        </div>
        <kendo-textbox  class="montserrat font20" placeholder="Email" (blur)="showResetBtn()"></kendo-textbox>
      </div>
      <div class="error_msg d-flex justify-content-center align-items-center pt-20" *ngIf="!isResetBtn">
        <div class="line-20"><ion-icon class="mr-5" src="../../../../assets/svg/alert-circle.svg" ></ion-icon></div>
        <div class="line-20"><ion-text color="error100" class="bodyM-txt">Invalid email. Please check and try again.</ion-text></div>
      </div>
      <div class="pt-30 text-center"  *ngIf="isResetBtn">
        <ion-button fill="solid" color="" class="filled-icon-btn" disabled="true">
          <ion-text class="capitalize roboto" color="">Send Reset Link</ion-text>
          <ion-icon src="../../../../../../../assets/svg/Airplane.svg" class="ml-10"></ion-icon>
        </ion-button>
      </div>
      <div class="pt-30 text-center" *ngIf="!isResetBtn">
        <ion-button fill="solid" color="" class="filled-icon-btn"(click)="showBacktoLogin()" >
          <ion-text class="capitalize roboto" color="">Send Reset Link</ion-text>
          <ion-icon src="../../../../../../../assets/svg/Airplane.svg" class="ml-10"></ion-icon>
        </ion-button>
      </div>
    </form>
    <div class="pt-50 text-center" *ngIf="!isResetPasswordForm">
      <ion-button fill="solid" color="" class="filled-icon-btn" (click)="backtoLogin()" >
        <ion-text class="capitalize roboto" color="">Back to login</ion-text>
        <ion-icon src="../../../../assets/svg/arrow-right.svg" class="ml-10"></ion-icon>
      </ion-button>
    </div>
  </div>
</div>

<div *ngIf="isMobile">
  <clinic-warning></clinic-warning>
</div>