

export const environment = {
  production: false,

  currentEnvironment: 'Development',
  
  recaptchaSiteKey: '6Lery-UpAAAAALm41r_hVCHakR5CvEhOyJ0v4OMK',
  myqone_url: "https://qore-dev.myqone.com",
  patient_url: "https://qore-dev.myqone.com/patient",
  auth_url: 'https://qore-dev.myqone.com/auth',
  qappoints_url: 'https://qore-dev.myqone.com/qappointments',
  communication_url: 'https://qore-dev.myqone.com/communication/',
  otc_url: 'https://devservices.qpathways.com/otc/',
  dms_url: 'https://qdrive-dev.myqone.com/dms',
  secretKey: '=a@S;nh9WwC#)Ph+D>;8F8e&"`P|-pJ.',
  pebackend_url: 'https://qpatientengsrv-dev.myqone.com/pe2backend/',
  auditUrl: "https://qore-dev.myqone.com/audit/v1/",
  qintake_url:"https://qpatient-dev.myqone.com/qintake",
  allowedOrigins : ['https://patientsso.demo.healthepayment.com'],

  firebaseConfig: {
    // apiKey: 'AIzaSyAf6l7qoiXHWg1n4V0gqaWC-jXm4yvVkwA',
    apiKey: 'AIzaSyCKHy-uhhXbITqXSteKv7lAKdAVbFwpPMY',
    authDomain: 'qpathways-dev.firebaseapp.com',
    databaseURL: 'https://qpathways-dev.firebaseio.com',
    projectId: 'qpathways-dev',
    storageBucket: 'qpathways-dev.appspot.com',
    messagingSenderId: '413178660353',
    appId: '1:413178660353:web:6f8e21f6ecd9b665d4a200',
    measurementId: 'G-K21LBEKQLR',
    vapidKey:
      'BPnM0ilOA6PD4yKM7FtYFys9c3zLRw-OKyxQOmaC6aOtsShKGXKhSPqksihzlROOA8ByJ_t5DEZyPPSRIPmjC_E',
  },
};